@use '../../sass' as *;

.card {
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: $white;
  width: 100%;
  margin-bottom: 16px;
  transition: all 0.3s ease;
  position: relative;
  padding: 24px;
  overflow: visible;
}

.header {
  cursor: default;
  background-color: $white;
}

.headerContent {
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 2px solid transparent;
  padding-bottom: 0px;
}

.headerContentExpanded {
  border-bottom-color: #e0e0e0;
  padding-bottom: 12px;
}

.titleContainer {
  display: flex;
  justify-content: center;
  flex: 1;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }
}

.title {
  font-family: $nunito;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: $black;
}

.titleBold {
  font-weight: 700;
}

.headerContentWrapper {
  display: inline-flex;
  align-items: center;
  margin-top: 4px;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 8px;
  }
}

.toggleIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;
  transition: transform 0.3s ease;
  font-size: 16px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
}

.toggleIconDisabled {
  color: $grayLight;
  cursor: not-allowed;
}

.content {
  position: relative;
  animation: slideDown 0.3s ease;
  border-radius: 0 0 8px 8px;
  background-color: $white;
  padding-top: 16px;
  overflow: visible;
}

.body {
  margin-bottom: 20px;
  position: relative;

  :global(.password-requirements-popup) {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
  }

  input {
    position: relative;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.continueButton {
  padding: 10px 20px;
  background-color: $black;
  color: $white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: $fontWeightBold;
  text-transform: uppercase;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #333;
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
