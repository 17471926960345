@use '../../../../../../../../../shared/ui/design-tokens/src/lib/sass/text' as *;
@use '../../../../../../../../../shared/ui/ui-styles/src/lib/_mediaQueries.scss' as *;

.subtitle {
  font-family: $text-font-family-nunito;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.continueButtonWrapper {
  width: 100%;

  button {
    width: 100%;
    border-radius: 3px;
    @include is-desktop {
      width: 241px;
    }
  }
}
