.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 12px;
}

.progressBarBackground {
  background-color: #e8e8e8;
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  width: 100%;
}

.progressBarFill {
  background-color: #b4e0d8;
  border-radius: 3px;
  height: 6px;
}
