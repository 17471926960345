.container {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #fff;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid #d0d0d0;
}

.header h3 {
  color: #000;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.header div[role='button'] {
  cursor: pointer;
}

.body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  font-family: 'Nunito Sans', sans-serif;
  line-height: 24px;
}

.body .subtitle {
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
}

@media (min-width: 576px) {
  .container {
    padding: 18px;
    height: auto;
    width: 462px;
    border-radius: 9px;
    border: 0px solid #ddd;
    background: #fff;
  }
}
