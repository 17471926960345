@value shared: '../shared.module.css';
@value fontNunitoSans, colorSilver, colorRose from shared;

.container {
  font-family: fontNunitoSans;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 17px;
}

.container .progressBar {
  width: 277px;
  height: 12px;
  border: 1px solid colorSilver;
  border-radius: 11.5px;
  position: relative;
}

.container .progressFill {
  background-color: colorRose;
  border-radius: 11.5px;
  position: absolute;
  left: -1px;
  top: -1px;
  height: 12px;
  border: 1px solid colorRose;
  transition: width 0.5s;
}

.container .questionNumbers {
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  font-family: fontNunitoSans;
  margin-left: 12px;
  width: max-content;
}
