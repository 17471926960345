.container {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999999!important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  padding-bottom: 72px;
  gap: 24px;
}

.container .bannerContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-left: 48px;
  margin-right: 48px;
}

.container .mobileScreenshots {
  height: 331px;
  padding-left: 24px;
  padding-right: 24px;
}

.container .bannerContainer .logoImage {
  border-radius: 40px;
  width: 80px;
  height: 80px;
}

.container .bannerContainer .textContainer {
  text-align: center;
}

.container .bannerContainer .textContainer .title {
  font-family: 'InterDisplay', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 8px;
}

.container .bannerContainer .textContainer .description {
  font-family: 'InterDisplay';
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-align: center;
}

.container .bannerContainer .openButton {
  background-color: #000000;
  color: #ffffff;
  border-radius: 40px;
  padding: 12px 71px;
  width: 100%;
  text-decoration: none;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
}

.container .bannerContainer .closeNewMobileBannerText {
  font-family: 'InterDisplay', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  background-color: transparent;
  border: none;
  color: #000000 !important;
}

.container .bannerContainer .openButton:active {
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease;
}

:global(.scrollContain) {
  overflow: hidden !important;
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
}
