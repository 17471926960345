.banner {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center;
}

.banner h1 {
  font-family: 'Playfair Display';
  font-size: 36px;
  font-weight: 700;
  line-height: 55.99px;
  text-align: center;
  max-width: 327px;
  padding-bottom: 16px;
}

.banner p {
  text-align: center;
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  margin: 0;
  max-width: 345px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.banner button {
  border: none;
  min-width: 327px;
  min-height: 48px;
  border-radius: 3px;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 16px;
  cursor: pointer;
}

.themeDark {
  color: #000;
}

.themeDark a,
.themeDark a:visited,
.themeDark a:hover {
  color: #fff;
  text-decoration: underline;
}

.themeDark button {
  background-color: #fff;
}

.themeLight {
  color: #fff;
}

.themeLight a,
.themeLight a:visited,
.themeLight a:hover {
  color: #000;
  text-decoration: underline;
}

.themeLight button {
  background-color: #000;
  color: #fff;
}

.themeLight .secondaryButton {
  background-color: #fff;
  border: solid 1px #000;
  color: #000;
}

.themeDark .secondaryButton {
  background-color: #000;
  color: #fff;
}

.themeDark .secondaryButton + button {
  border: solid 1px #000;
}

.themeLightTransparent,
.themeLightTransparent a,
.themeLightTransparent a:visited,
.themeLightTransparent a:hover {
  color: #000;
}
.themeDarkTransparent,
.themeDarkTransparent a,
.themeDarkTransparent a:visited,
.themeDarkTransparent a:hover {
  color: #fff;
}
.themeLightTransparent .container,
.themeDarkTransparent .container {
  background: rgba(255, 255, 255, 0.5);
  height: auto;
  width: 100%;
  margin: 40px;
  padding: 16px;
}
.themeDarkTransparent .container {
  background: rgba(0, 0, 0, 0.5);
}
.themeLightTransparent .container h1,
.themeDarkTransparent .container h1 {
  font-size: 30px;
  line-height: normal;
  max-width: unset;
  min-width: unset;
}
.themeLightTransparent .container p,
.themeDarkTransparent .container p {
  font-size: 14px;
  line-height: 21px;
  max-width: unset;
  min-width: unset;
}

@media (min-width: 768px) {
  .banner {
    height: 450px;
  }
  .banner h1 {
    min-width: 488px;
    font-size: 42px;
  }
  .banner p {
    min-width: 768px;
  }
  .secondaryButton {
    margin-right: 16px;
  }

  .themeLightTransparent .container,
  .themeDarkTransparent .container {
    width: 520px;
    padding: 24px;
  }

  .themeLightTransparent .container h1,
  .themeDarkTransparent .container h1 {
    font-size: 42px;
    margin: 0;
  }

  .themeLightTransparent .container p,
  .themeDarkTransparent .container p {
    font-size: 16px;
    line-height: 24px;
  }
}
