.tooltip {
  border-radius: 0;
  background-color: #363636 !important;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  opacity: 1 !important;
}

.tooltip:global(.place-top)::after {
  border-top-color: #363636 !important;
}

.tooltip:global(.place-right)::after {
  border-right-color: #363636 !important;
}
