@value shared: '../shared.module.css';
@value sm from shared;

.closeButtonContainer {
  display: none;
}

@media screen and (min-width: sm) {
  .closeButtonContainer {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.openBody {
  overflow: hidden;
}

.modalContent {
  background-color: #fff;
  outline: 0;
}
