.web-cards__container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
}

.web-cards__container h3 {
  font-family: 'Playfair Display';
  font-size: 30px;
  line-height: 1.2;
  text-align: center;
  font-weight: 400;
  margin: 18px 20px 6px 20px;
}

.web-cards__cards {
  display: flex;
  flex-direction: column;
}

.web-cards__cards__card {
  margin-bottom: 7px;
}

.web-card-image {
  text-align: center;
}

.web-card-image img {
  max-width: 100%;
  max-height: 220px;
}

.web-card-text {
  margin: 0 58px;
}

.web-card-text h4 {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 1.33;
  text-align: center;
  margin: 3px 0 7px 0;
}

.web-card-text p {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 0 0 17px 0;
}

.web-card-cta a {
  margin: 0 auto 23px auto;
  width: 157px;
  padding: 11px 16px;
}

@media screen and (min-width: 1024px) {
  .web-cards__container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
  }

  .web-cards__container h3 {
    margin-top: 31px;
    font-size: 36px;
    line-height: 1.17;
    margin-bottom: 12px;
  }

  .web-cards__cards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 45px 0 -53px 0;
  }

  .web-cards__cards__card {
    max-width: 384px;
    margin-bottom: 77px;
  }

  .web-card-image {
    margin: 0;
    height: 175px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .web-card-text {
    margin: 0 19px;
  }

  .web-card-text h4 {
    margin-top: 15px;
  }

  .web-card-text p {
    margin: 0 42px 23px 42px;
  }

  .web-card-image img {
    width: 384px;
    max-width: 384px;
  }
}

@media screen and (max-width: 1200px) {
  .web-cards__cards {
    justify-content: space-around;
  }
}
