.toolTip[data-theme~='default'],
.toolTip[data-theme~='left-aligned'] {
  font-family: 'Nunito Sans', sans-serif;
  width: 226px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #363636;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  padding: 12px;
}

.toolTip[data-theme~='left-aligned'] {
  text-align: left;
}
