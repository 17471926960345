.avatar i {
  font-size: 42px;
  color: #9b9b9b;
}

.avatar img {
  height: 42px;
  width: 42px;
  border-radius: 21px;
  overflow: hidden;
}

@media screen and (min-width: 992px) {
  .profile-button i {
    height: 42px;
    width: 42px;
    border-radius: 21px;
    color: #9b9b9b;
  }
  .profile-button:hover i {
    color: #4a4a4a;
  }
}
