.inputWrapper {
  width: 100%;
}

.inputContainerMargin {
  margin-right: 20px;
}

.inputWrapper .inputContainer {
  position: relative;
}

.inputWrapper .inputContainer .inputLabel {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 1.25;
}

.inputWrapper .inputContainer .inputLabel .input {
  height: 48px;
  width: 100%;
  background: #fff;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  padding: 12px;
  margin-top: 8px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
}

.inputWrapper .inputContainer .inputLabel .inputError {
  margin: 5px 0 6px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 1.25;
  color: #fb6a66;
  min-height: 15px;
}

@media screen and (max-width: 768px) {
  .inputContainerMargin {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .inputWrapper .inputContainer .inputLabel .input {
    font-size: 14px;
  }
}
