@value shared: "../../../shared/shared.module.css";
@value sm from shared;

.container {
  border-top: solid 1px #d8d8d8;
  padding: 12px;
  margin-top: auto;
}

.container i {
  color: #cccccc;
  margin-left: 6px;
}

.shopCampaign {
  border: none;
}

.item,
.total {
  display: flex;
  justify-content: space-between;
}

.item {
  margin-bottom: 6px;
  font-size: 12px;
}

.item button {
  margin-left: 6px;
}

.item i {
  color: #cccccc;
  margin: 0;
}

.shipping {
  display: flex;
  flex-direction: column;
}

.shipping p:nth-of-type(2) {
  margin-top: 3px;
  color: #666666;
}

.shipping p:nth-of-type(2) span {
  color: #fb6a66;
  font-weight: 700;
}

.total {
  font-weight: bold;
}

.tooltip {
  max-width: 250px;
}

.tooltip a {
  color: #ffffff;
}

@media (min-width: sm) {
  .container {
    padding: 12px;
  }
}
