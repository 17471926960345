@value shared: '../shared.module.css';
@value md from shared;
@value lg from shared;

.errorPage {
  text-align: center;
  padding: 70px 0;
}

.content {
  margin-top: 15px;
  margin-bottom: 44px;
  color: black;
}

.content h1 {
  margin: 0 auto 19px;
  font-size: 30px;
  font-family: 'Playfair Display', serif;
  font-weight: normal;
  line-height: 1.2;
}

.content p {
  margin: 0 auto 18px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

.imageWrapper {
  width: 100%;
  line-height: 0;
}

.imageWrapper img {
  max-width: 100%;
}

@media screen and (min-width: md) {
  .separator {
    display: none;
  }

  .imageWrapper {
    width: 456px;
    margin: 0 auto;
  }
}

@media screen and (min-width: lg) {
  .content {
    margin-top: 60px;
  }

  .content h1 {
    font-size: 42px;
    line-height: 1.14;
    margin: 0 auto 17px;
  }

  .content p {
    margin: 0 auto 29px;
  }
}
