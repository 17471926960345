.pffRow {
  display: flex;
  gap: 6px;
}

.pffCol {
  width: 100%;
  display: flex;
  gap: 6px;
}

.pffInline {
  display: flex;
}

@media screen and (max-width: 500px) {
  .pffRow {
    flex-direction: column;
    gap: 0;
  }
}
