@use '../../../sass/colors';
@use '../../../sass/fonts';
@use '../../../sass/mediaQueries';

.plansOptionsWrapper {
  display: flex;
  max-width: 808px;
  padding-bottom: 0;
  flex-flow: row nowrap;
  padding-top: 40px;
  flex-direction: row-reverse;

  .planCardContainer {
    background-color: colors.$white;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
    padding: 4px;
    position: relative;
    border: 1px solid colors.$grayLight;
    flex: 1;

    &:hover {
      cursor: pointer;
    }

    .planSelectedLabel {
      display: none;
    }

    .plansPriceContainer {
      display: flex;
      flex-direction: column;
    }

    .plansContent {
      padding: 4px;
      height: 100%;
      border-radius: 8px;
      background: colors.$white;
      position: relative;

      .plansContentBadge {
        width: 107px;
        height: 107px;
        position: absolute;
        top: 14px;
        right: 20px;
      }

      .plansTitle {
        display: flex;
        justify-content: flex-start;
        gap: 4px;
        align-items: center;
        h4 {
          font-family: fonts.$playfair, sans-serif;
          font-size: 24px;
          font-weight: normal;
          margin: 0;
          padding: 0;
        }
      }

      .plansContentPrice {
        font-family: fonts.$nunito, sans-serif;
        font-weight: fonts.$fontWeightBold;
        font-size: 24px;
        line-height: 0.86;
        margin: 0;
        padding: 0;
        span {
          font-size: 8px;
        }
      }

      .plansLineDivider {
        border: 0;
        border-top: 1px solid colors.$grayVeryLight;
        margin: 8px 0 12px 0;
        opacity: 0.6;
      }

      .contentBottomDisclaimer {
        width: 235px;
        font-family: fonts.$nunito, sans-serif;
        font-size: 12px;
        line-height: 1.25;
        text-align: center;
        color: colors.$grayDark;
        margin: auto;
        padding-top: 3px;
        padding-bottom: 12px;
      }
    }
  }

  .plansOptionsSelected {
    background: colors.$primary;
    border-radius: 12px;

    .planSelectedLabel {
      display: block;
      width: fit-content;
      border-radius: 4px;
      background-color: colors.$primary;
      position: absolute;
      padding: 8px 24px;
      top: -34px;
      left: 0;
      right: 0;
      font-weight: fonts.$fontWeightBold;
      font-family: fonts.$nunito;
      text-align: center;
      font-size: 16px;
      color: colors.$white;
      margin: auto;
    }
  }
}

.plansLegalText {
  font-family: fonts.$nunito, sans-serif;
  width: 316px;
  font-size: 12px;

  text-align: center;
  color: colors.$gray;
  margin: auto;
  margin-top: 10px;

  sup {
    font-size: xx-small;
  }
}
.buildMyBoxWrapper {
  display: flex;
  justify-content: center;
  margin: 30px 0;
}

@media (min-width: mediaQueries.$small) {
  .plansOptionsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: mediaQueries.$medium) {
  .plansOptionsWrapper {
    column-gap: 30px;
    padding: 48px 0 0;
    .annualPlanCard {
      grid-column: 1;
    }
    .seasonalPlanCard {
      grid-column: 2;
    }
    .planCardContainer {
      padding: 8px;
      border-radius: 16px;
      .plansContent {
        width: 320px;
        padding: 24px;
        border-radius: 12px;

        .plansTitle {
          justify-content: space-between;
          h4 {
            font-size: 36px;
          }
        }

        .plansContentPrice {
          font-size: 42px;
          span {
            font-size: 16px;
          }
        }

        .plansLineDivider {
          margin: 4px 0 4px 0px;
        }

        .plansContentBadge {
          right: 15px;
        }

        .contentBottomDisclaimer {
          padding-top: 2px;
        }
      }
    }

    .plansOptionsSelected {
      border-radius: 18px;
    }
  }

  .plansLegalText {
    margin-top: 12px;
  }
}
