.not-found-error {
  text-align: center;
  padding: 5% 10px 20% 10px;
}

.not-found-error h1 {
  font-size: 90px;
  margin: 0;
  padding: 10px 0;
  line-height: 50px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  color: #ceacc7;
}

.not-found-error h2 {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  text-align: center;
  color: #ceacc7;
}

.not-found-error .btn-back-home {
  display: inline-block;
  padding: 6px 10px 5px;
  text-decoration: none;
  color: #222;
  background: #fff;
  border: 1px solid #444;
  cursor: pointer;
  line-height: 15px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
}
