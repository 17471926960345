@use '../../../sass/colors';
@use '../../../sass/fonts';
@use '../../../sass/mediaQueries';

.container {
  display: flex;
  gap: 8px;
  position: relative;
  .arrowButton {
    position: absolute;
    top: 162px;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    z-index: 1;
    font-size: 48px;
    font-weight: 400;
    display: none;
    svg {
      width: 42px;
      height: 28px;
      fill: #000;
    }
  }

  .arrowButtonLeft {
    left: 15px;
  }

  .arrowButtonRight {
    right: 15px;
  }
}

@media (min-width: mediaQueries.$medium) {
  .container {
    .arrowButton {
      display: block;
    }
    .arrowButtonLeft {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
