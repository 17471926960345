@value shared: '../shared.module.css';
@value md from shared;
@value fontNunitoSans: font-nunito-sans from shared;

.perkListContainer {
  align-items: center;
  background-color: #ede7e0;
  display: flex;
  flex-direction: column;
  font-family: fontNunitoSans;
  padding: 0;
  text-align: center;
}

.perkListContainer p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
}

.perkListContainer ul.perkList {
  display: flex;
  flex-direction: column;
  margin-block-end: 0;
  margin-block-start: 0;
  padding-inline-start: 0;
  align-items: flex-start;
  gap: 24px;
}

.perkListContainer ul.perkList li {
  display: flex;
  align-items: flex-start;
  gap: 18px;
}

.perkListContainer ul.perkList li .iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.perkListContainer ul.perkList li:last-child {
  margin-bottom: 0;
}

.perkListContainer ul.perkList li .perkTitle {
  font-size: 16px;
}

.perkListContainer ul.perkList li img {
  width: 24px;
  margin-top: 4px;
}

.perkListContainer ul.perkList li .perkDescription {
  text-align: left;
}

.perkListContainer ul.perkList li .perkDescription strong {
  display: block;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 4px;
}

.perkListContainer ul.perkList li .perkDescription p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.perkListContainer ul.perkList li .perkDescription p .termsLink,
.perkListContainer ul.perkList li .perkDescription p .termsLink a {
  color: #4a4a4a;
  font-family: fontNunitoSans;
  font-size: 12px;
  line-height: 18px;
}

.perkListContainer ul.perkList li .perkDescription p .termsLink a {
  text-decoration: underline;
}

@media screen and (min-width: md) {
  .perkListContainer {
    width: 100%;
  }

  .perkListContainer div.wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .perkListContainer ul.perkList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    height: auto;
    max-width: 645px; /* (307.5 x 2 = 615) + (30 gap) */
    gap: 30px;
  }

  .perkListContainer ul.perkList li {
    flex-basis: auto;
    max-width: 307.5px;
  }

  .perkListContainer ul.perkList li .perkTitle img {
    max-height: 135px;
    max-width: 144px;
  }

  .perkListContainer ul.perkList li .perkDescription {
    text-align: left;
    margin: 0;
    max-width: unset;
  }
}
