.inputContainer {
  position: relative;
  width: 100%;
  margin-top: 4px;
}

.inputContainer .label {
  display: block;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 15px;
  position: relative;
}

.inputContainer .label .input {
  height: 48px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  padding: 12px;
  margin-top: 8px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
}

.inputContainer .label .inputError {
  color: #fb6a66;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 1.25;
  margin: 5px 0 6px;
  min-height: 15px;
}

.inputContainer .label .input:disabled {
  background: #eeeff0;
}

@media (min-width: 992px) {
  .inputContainer .label .input {
    font-size: 14px;
  }
}
