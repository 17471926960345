// colors

$primaryVeryDark: #94403d;
$primaryDark: #c75552;
$primary: #fb6a66;
$primaryLight: #fa9c98;
$primaryVeryLight: #faccca;

$secondaryVeryDark: #497a71;
$secondaryDark: #68ada1;
$secondary: #b4e0d8;
$secondaryLight: #c6f7ee;
$secondaryVeryLight: #e6fffa;

$black: #000000;
$grayVeryDark: #363636;
$grayDark: #4a4a4a;
$gray: #9b9b9b;
$grayLight: #d0d0d0;
$grayVeryLight: #e8e8e8;
$white: #ffffff;
$link: #4a72ff;

$statusRed: #ff3b30;
$statusYellow: #f7b343;
$statusGreen: #9dd321;
$colorPearl: #ede7e0;
