@use '../../sass/colors';
@use '../../sass/fonts';
@use '../../sass/mediaQueries';

.wrapper {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 8px 0;
  padding: 8px;
}

.title {
  font-family: fonts.$seriouslyNostalgic;
  font-size: 30px;
  font-weight: fonts.$fontWeightRegular;
  text-align: center;
  margin: 0;
}

@media (min-width: mediaQueries.$medium) {
  .wrapper {
    h3 {
      padding: 20px;
    }
  }
}

.primaryColor {
  color: #fb6a66;
}
