@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700&display=swap');

.read-more div,
.read-more button {
  margin: 0;
  margin-top: 5px;
  padding: 0;
  opacity: 0.6;
  color: #000000;
  font-family: 'Nunito Sans';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: transparent;
  border: none;
}

.read-more button {
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
  margin-top: 5px;
}

.read-more a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

.read-more > div > button {
  opacity: 1;
}

.read-more > div > span {
  display: inline;
  margin-right: 10px;
}
