@use '../../sass/colors';
@use '../../sass/fonts';
@use '../../sass/mediaQueries';

.wrapper {
  display: flex;
  border-radius: 6px;
  border: 2px solid colors.$black;
  background-color: colors.$white;
  padding: 24px;
  position: relative;

  .wrapperLarge {
    padding: 24px;
  }

  &.wrapperCenter {
    flex-flow: column nowrap;
  }

  &.wrapperLeft {
    flex-flow: row nowrap;
    gap: 29px;
    justify-content: flex-start;
  }

  .priceWrapper {
    display: flex;
  }

  .priceWrapper h3 {
    font-family: fonts.$playfair;
    font-weight: 400;
    margin: 0;
  }

  &.wrapperCenter .priceWrapper {
    font-size: 18px;
    flex-direction: row;
    justify-content: space-between;
  }

  &.wrapperLeft .priceWrapper {
    flex-direction: column;
    justify-content: flex-start;
    font-size: 30px;
    width: 187px;
  }

  &.wrapperLarge .priceWrapper {
    font-size: 24px;
  }

  .billingData {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &.wrapperCenter .billingData {
    align-items: flex-end;
  }

  &.wrapperLeft .billingData {
    align-items: flex-start;
  }

  .billingInformation {
    font-family: fonts.$nunito;
    font-size: 12px;
    line-height: 24px;
    color: #979797;
    margin: 0;
    font-weight: 600;
  }

  .wrapperBadge {
    position: absolute;
    left: 0;
    right: 0;
    top: -1.4rem;
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    align-content: center;
  }

  .badge {
    font-family: fonts.$nunito;
    padding: 5px 16px;
    border-radius: 20px;
    background-color: colors.$black;
    color: colors.$white;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    justify-content: center;
    align-items: center;
    display: flex;
    letter-spacing: 1.4px;
    position: absolute;
  }

  &.wrapperLarge .badge {
    padding: 10px 24px;
    font-size: 14px;
  }

  .badgeCenter {
    justify-content: center;
  }

  .badgeLeft {
    padding-left: 20px;
  }

  .badgeRight {
    padding-right: 20px;
    justify-content: flex-end;
  }

  &.wrapperLeft .wrapperList {
    justify-content: center;
  }

  &.wrapperCenter .wrapperList {
    justify-content: flex-start;
  }

  .wrapperList {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 0;
    gap: 9px;
    margin-top: 8px;

    li {
      display: flex;
      gap: 9px;
      font-size: 14px;
      font-family: fonts.$nunito;

      * {
        margin: 0;
      }

      svg {
        width: 16px;
        height: 21px;
        fill: colors.$primary;
      }

      p {
        display: inline;
      }
    }
  }

  &.wrapperLarge .wrapperList {
    margin-top: 22px;
    gap: 22px;

    li {
      font-size: 18px;
    }
  }

  .wrapperLeft .wrapperList {
    justify-content: center;
  }
}

@media (min-width: mediaQueries.$medium) {
  .wrapper {
    padding: 24px;

    &.wrapperLeft {
      gap: 64px;
    }
  }

  .wrapperLarge {
    padding: 32px;
  }

  .wrapperList {
    li {
      span {
        width: 16px;
      }
      svg {
        width: 16px;
        height: auto;
      }
    }
  }

  .wrapperLeft {
    .wrapperList {
      margin-top: 0;
    }
  }
}
