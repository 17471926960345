@font-face {
  font-family: 'Founders Grotesk';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/FoundersGrotesk-Bold.otf')
    format('opentype');
  font-weight: bold;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/FoundersGrotesk-BoldItalic.otf')
    format('opentype');
  font-weight: bold;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/FoundersGrotesk-Light.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/FoundersGrotesk-LightItalic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/FoundersGrotesk-Medium.otf')
    format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/FoundersGrotesk-MediumItalic.otf')
    format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/FoundersGrotesk-Regular.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/FoundersGrotesk-RegularItalic.otf')
    format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/FoundersGrotesk-Semibold.otf')
    format('opentype');
  font-weight: 600;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Founders Grotesk';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/FoundersGrotesk-SemiboldItalic.otf')
    format('opentype');
  font-weight: 600;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Seriously Nostalgic';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/SeriouslyNostalgic-Condensed.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Seriously Nostalgic';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/SeriouslyNostalgic-Regular.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Seriously Nostalgic';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/SeriouslyNostalgic-SemiCond.otf')
    format('opentype');
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Seriously Nostalgic';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/SeriouslyNostalgicFnIt-SmCn.otf')
    format('opentype');
  font-weight: normal;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Seriously Nostalgic';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/SeriouslyNostalgicItal-SmCn.otf')
    format('opentype');
  font-weight: normal;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Seriously Nostalgic';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/SeriouslyNostalgicItal-UCn.otf')
    format('opentype');
  font-weight: normal;
  font-style: italic;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Romie';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/romie/Romie-Light.woff2')
    format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Romie';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/romie/Romie-Regular.woff2')
    format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Romie';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/romie/Romie-Medium.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Romie';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/romie/Romie-Bold.woff2')
    format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Romie';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/romie/Romie-Black.woff2')
    format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'InterDisplay';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/inter-display/InterDisplay-Light.woff2')
    format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'InterDisplay';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/inter-display/InterDisplay-Regular.woff2')
    format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'InterDisplay';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/inter-display/InterDisplay-Medium.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'InterDisplay';
  src: url('https://scontent01.fabfitfun.com/shared/webfonts/inter-display/InterDisplay-SemiBold.woff2')
    format('woff2');
  font-weight: 600;
  font-style: normal;
}

// fonts
$nunito: 'Nunito Sans', sans-serif;
$playfair: 'Playfair Display', sans-serif;
$seriouslyNostalgic: 'Seriously Nostalgic', sans-serif;
$foundersGrotesk: 'Founders Grotesk', sans-serif;
$avenir: 'Avenir', sans-serif;
$romie: 'Romie', sans-serif;
$interDisplay: 'InterDisplay', sans-serif;

// weights
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightBold: 700;
$fontWeightExtraBold: 800;
