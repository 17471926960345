@use '../../sass/colors';
@use '../../sass/fonts';
@use '../../sass/mediaQueries';

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  position: relative;

  .cardDetails {
    display: flex;
    flex-direction: row;
    gap: 8px;

    div {
      height: 35px;
      line-height: 36px;
    }

    .cardType {
      width: 54px;
      overflow: hidden;
      background-repeat: no-repeat;
      background-size: contain;

      &.amex {
        background-image: url('./assets/Amex.svg');
      }
      &.mastercard {
        background-image: url('./assets/Mastercard.svg');
      }
      &.visa {
        background-image: url('./assets/Visa.svg');
      }
      &.discover {
        background-image: url('./assets/Discover.svg');
      }
      &.jcb {
        background-image: url('./assets/JCB.svg');
      }
      &.dinersclub {
        background-image: url('./assets/DinersClub.svg');
      }
      &.paypal {
        background-image: url('./assets/PayPal.svg');
      }
      &.applepay {
        background-image: url('./assets/ApplePay.svg');
      }
      &.venmo {
        background-image: url('./assets/Venmo.svg');
      }
    }
  }
}

@media (min-width: mediaQueries.$medium) {
  .wrapper {
    .cardDetails {
      padding-left: 18px;
    }
  }
}
