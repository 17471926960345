.box-copy {
  width: 117px;
  height: 72px;
  position: relative;
  right: 59px;
}

.box-copy img {
  width: 100%;
  transform: rotate(-5deg) scale(1.2);
}

.banner {
  display: flex;
  justify-content: center;
}

.all-text {
  position: relative;
}

.share-a-Taste-of-Fab {
  height: 24px;
  font-family: 'Nunito Sans';
  margin: auto;
  padding-top: 13px;
  font-size: 18px;
  font-weight: 630;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.you-have-3-Starter-B {
  height: 19px;
  margin: auto;
  padding-top: 4px;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.banner-link {
  color: #fff;
  font-weight: bold;
}

.banner-link:visited {
  color: #ffffff;
}

.times {
  top: 27px;
  right: 29px;
  position: absolute;
  font-family: FontAwesome5Pro;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: none;
  background: transparent;
}

.mask {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  text-align: center;
  height: 72px;
  background-color: #000;
}

@media (max-width: 480px) {
  .box-copy {
    display: none;
  }

  .share-a-Taste-of-Fab {
    height: 48px;
    margin: 0 60px 4px;
    line-height: 1.33;
    letter-spacing: normal;
  }

  .times {
    font-size: 14px;
  }

  .you-have-3-Starter-B {
    height: 36px;
    margin: 4px 0 0;
    line-height: 1.29;
  }

  #secondLine::before {
    content: '\a';
    white-space: pre;
  }

  .mask {
    min-height: 119px;
  }
}

@media (max-width: 320px) {
  .mask {
    min-height: 130px;
  }
}
