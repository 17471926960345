@use '../../../../sass/colors';
@use '../../../../sass/fonts';
@use '../../../../sass/mediaQueries';

.plansPerksList {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
}

@media (min-width: mediaQueries.$medium) {
  .plansPerksList {
    padding: 8px 0;
    gap: 8px;
    margin-bottom: 0;
    line-height: 21px;
  }
}
