@use '../../../../sass/colors';
@use '../../../../sass/fonts';
@use '../../../../sass/mediaQueries';

.perk {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  position: relative;

  .perkIcon {
    display: flex;
    align-items: center;

    svg {
      width: 9px;
    }
  }
  .perkText {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: fonts.$nunito, sans-serif;
    font-size: 10px;
    font-weight: fonts.$fontWeightRegular;
    line-height: normal;
    list-style-type: none;
    p {
      text-wrap: nowrap;
      margin: 0;
    }
  }

  .plansTooltip {
    position: relative;
    display: inline-block;
    transform: translateX(4px) translateY(4px);
    margin-top: -6px;

    &:after,
    &:before {
      content: '';
      --scale: 0;
      --arrow-size: 10px;

      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-62%) translateY(var(--translate-y, 0)) scale(var(--scale));
      transition: 90ms transform;
      transform-origin: bottom center;
      z-index: 10;
      text-wrap: wrap;
    }

    &:after {
      content: '';
      transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    }

    &:before {
      --translate-y: calc(-100% - var(--arrow-size));

      content: attr(data-tooltip);
      color: colors.$white;
      padding: 14px;
      border-radius: 8px;
      width: 160px;
      z-index: 10;
      background: colors.$grayVeryDark;
      font-family: fonts.$nunito, sans-serif;
      font-size: 12px;
    }

    &:hover::before,
    &:hover::after {
      --scale: 1;
    }

    &:after {
      --translate-y: calc(-1 * var(--arrow-size));

      content: '';
      border: var(--arrow-size) solid transparent;
      border-top-color: colors.$grayVeryDark;
      transform-origin: top center;
    }
  }

  .plansTooltipShort {
    &:before {
      width: 160px;
    }
  }

  &.perkExcluded {
    text-decoration: line-through;
  }
}

@media (min-width: mediaQueries.$medium) {
  .perk {
    gap: 8px;
    .perkIcon {
      svg {
        width: inherit;
        height: inherit;
        margin-top: 5px;
      }
    }
    .perkText {
      font-size: 16px;
    }

    .plansTooltip {
      transform: translateX(8px) translateY(2px);
      &:before {
        font-size: 14px;
      }
    }

    .plansTooltipShort {
      &:before {
        width: 200px;
      }
    }
  }
}

@media (min-width: mediaQueries.$large) {
  .perk {
    gap: 8px;

    .perkText {
      font-size: 14px;
    }
  }
}
