.invite-popup-overlay {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 150;
}

.invite-popup-container {
  width: 800px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
}

.invite-popup {
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fef5f1;
  border-radius: 6px;
  padding: 50px;
}

.invite-popup__info {
  max-width: 338px;
}

.invite-popup__info__title {
  font-family: 'Playfair Display';
  font-size: 42px;
  font-weight: normal;
  line-height: 1.14;
  color: #000000;
  margin: 9px 0 18px;
}

.invite-popup__info__title--small {
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 800;
  line-height: 1.25;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.invite-popup__info__title--msg {
  font-family: 'Nunito Sans';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 24px;
}

.invite-popup__info__button {
  border: none;
  background-color: #000;
  height: 48px;
  max-width: 221px;
  width: 100%;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: bold;
  line-height: 1.13;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.invite-popup__info__button:hover {
  cursor: pointer;
  background-color: #9b9b9b;
  transition: all ease 0.5s;
}

.invite-popup__box {
  max-width: 327px;
}

.invite-popup__box img {
  width: 100%;
}

.invite-popup__close {
  width: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.invite-popup__close:hover {
  cursor: pointer;
}

.invite-popup__close img {
  width: 100%;
}

@media (max-width: 800px) {
  .invite-popup-container {
    width: 95%;
  }
  .invite-popup {
    flex-direction: column;
  }
  .invite-popup__info {
    margin-top: 18px;
    max-width: 80%;
    order: 2;
    text-align: center;
  }
  .invite-popup__box {
    max-width: 248px;
    order: 1;
  }
}

@media (max-width: 480px) {
  .invite-popup {
    padding: 53px 18px 18px;
  }

  .invite-popup__info {
    max-width: 338px;
  }

  .invite-popup__info__title {
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: normal;
  }

  .invite-popup__info__title--small {
    font-size: 11px;
    font-weight: 800;
    line-height: 1.09;
    letter-spacing: 2px;
  }

  .invite-popup__info__button {
    max-width: none;
  }
}
