.gwpCheckBoxContainer {
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  display: flex;
  font-family: 'Nunito Sans', sans-serif;
  color: #4a4a4a;
}

.checkboxContainer label {
  cursor: pointer;
}

.checkboxContainer {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
}

.gwpCheckBox {
  display: none;
  min-width: 20px;
}

.checkBoxCheckmark {
  position: relative;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.gwpCheckBox:checked ~ .checkBoxCheckmark {
  background-color: #f56a6a;
  border-color: #f56a6a;
}

.gwpCheckBox:checked ~ .checkBoxCheckmark:after {
  display: inline-block;
  content: '';
  width: 4px;
  height: 8px;
  margin-bottom: 2px;
  border: inset #fff;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}
