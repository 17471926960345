.disclaimerContainer {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.04);
  padding: 18px;
  max-width: 365px;
}

.disclaimerContainer h6 {
  font-weight: 700;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 4px 0;
}

.disclaimerContainer p {
  font-weight: 400;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 16px 0;
  color: #4a4a4a;
}

.disclaimerContainer p.aboutYourSubscription {
  margin: 0;
}

.codContainer {
  line-height: 130%;
  margin: 10px 0 0;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Nunito Sans', sans-serif;
}

.codContainer > a {
  font-size: inherit;
  text-decoration: underline;
  color: #fb6a66;
}

.orderLegalTextPadding {
  padding: 0 10px;
}

.orderLegalTextLight {
  line-height: 15px;
  margin: 0 0 24px 0;
  opacity: 0.6;
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
}

.checkboxContainer {
  margin-top: 16px;
}

.disclaimerContainer a {
  color: #4a72ff;
}

.disclaimerContainer a:visited {
  color: #3858cb;
}

.disclaimerContainer a:hover {
  color: #8ea7ff;
}

.disclaimerContainer a:active {
  color: #3858cb;
}
