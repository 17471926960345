.button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  height: 42px;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 2px;
}

.button.active {
  color: #000;
  border-bottom: 3px solid #000;
  padding-bottom: 0px;
}

.button.onlyTab {
  color: #000;
}

.button:focus {
  outline: none;
}

.button i {
  margin-right: 7px;
}
