@use '../../../sass/colors';
@use '../../../sass/fonts';
@use '../../../sass/mediaQueries';

.item {
  text-align: center;
  font-family: fonts.$nunito;
  font-size: 16px;
  line-height: 27px;
  position: relative;
  scroll-snap-align: start;

  .clickableArea {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  .image {
    object-fit: cover;
    height: 167px;
    width: 100%;
  }

  .title {
    font-weight: 700;
    margin: 0;
  }

  .subtitle {
    margin: 0;
    max-width: 167px;
    margin: 0 auto;
  }

  .subtitleBold {
    font-weight: 700;
  }

  .promotion {
    margin: 0;
    font-weight: 700;
  }

  .price {
    margin: 0;
  }
}

@media (min-width: mediaQueries.$medium) {
  .item {
    .image {
      width: 100%;
      height: 252px !important;
    }
    .subtitle {
      max-width: 252px;
    }
  }
}
