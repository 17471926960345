@use '../../sass/mediaQueries';

.container {
  margin: 0;
  padding: 0;

  h2 {
    margin: 0;
    font-size: 18px;
  }

  .title {
    border-bottom: solid 1px #ddd;
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }
}

.modal {
  width: 100%;
  overscroll-behavior: contain;
  background-color: #fff;
  padding: 18px 16px;
  height: 1072px;

  h1 {
    padding: 0;
    text-align: left;
    font-size: 18px;
    font-weight: 700;
  }

  h2 {
    padding-top: 24px;
    margin: 0;
    font-size: 16px;
  }

  .header {
    border-bottom: solid 1px #d0d0d0;
    padding-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }

  form > div > div {
    margin-top: 24px;
    margin-right: 0;
  }
}

@media screen and (min-width: mediaQueries.$large) {
  .container {
    width: 692px;
    margin: 0;
    padding-left: 18px;
  }

  .modal {
    max-width: 654px;
    padding: 18px;
    height: unset;

    h1 {
      margin: 0;
      font-family: 'Nunito Sans';
      line-height: 18px;
    }

    form {
      input,
      select {
        border-radius: 3px;
      }
    }
  }
}
