@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700|Playfair+Display:700&display=swap');

.mybox-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 26px 12px 0;
  background-color: #ffffff;
  box-sizing: border-box;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
}

.buttons-container .fff-btn-primary-black-outline {
  width: 100%;
  margin-bottom: 12px;
  box-sizing: border-box;
  overflow: visible;
}

.buttons-container .fff-btn-primary-black-outline:last-child {
  margin-bottom: 0;
}

.status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.mybox-image {
  width: 156px;
  height: 106px;
  box-sizing: border-box;
}

.status-container h3 {
  margin: 0;
  font-family: 'Playfair Display';
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;
  color: #000;
}

.status-container h3 span {
  display: block;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  color: #000;
  margin: 20px 0 2px 0;
}

.status-container__body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #363636;
  margin: 0;
  white-space: break-spaces;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 4px;
}

.status-container__body i {
  margin-right: 6px;
}

.highlighted {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  color: #fb6a66;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
}

.status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 11px;
}

.address-title {
  display: block;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  color: #000;
  margin: 30px auto 5px auto;
}

.address-details {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
  margin: 5px auto 0;
  max-width: 180px;
  white-space: break-spaces;
  text-align: center;
}

.address-piece {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
  margin: 0 auto;
  max-width: 220px;
  white-space: break-spaces;
  text-align: center;
}

.challenge-container {
  flex-direction: row;
  max-width: 1200px;
  padding: 12px 0 40px;
  margin: 0 auto;
}

.challenge-status-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 18px 12px 40px 12px;
  margin: -38px auto 0 auto;
}

.challenge-status-container-desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.challenge-status-container-desktop span {
  display: block;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: left;
  color: #000;
  margin: 0 0 3px 0;
}

.challenge-status-container span {
  display: block;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  color: #000;
  margin: 0 0 22px 18px;
}

.challenge-mybox-image {
  width: 159px;
}

.challenge-mybox-image-desktop {
  width: 159px;
  margin: 8px 0 0 0;
}

.challenge-status {
  display: flex;
  margin-left: 12px;
}

.challenge-status-desktop {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.challenge-status__details {
  display: flex;
  flex-direction: column;
  margin: -17px 0 0 21px;
}

.challenge-status__details-desktop {
  display: flex;
  flex-direction: column;
  margin: 0 0 12px 0;
  box-sizing: border-box;
}

.challenge-status__body-detail-desktop {
  margin-top: 3px;
}

.with-address .challenge-status__details-desktop {
  max-width: 320px;
}

.with-address .read-more p {
  align-items: flex-start;
  flex-direction: column;
}

.challenge-status__details-desktop h3 {
  font-family: 'Playfair Display';
  font-size: 36px;
  font-weight: bold;
  line-height: 42px;
  letter-spacing: normal;
  color: #000000;
  margin: 1px 0 1px 1px;
}

.challenge-status__details h3 {
  font-family: 'Playfair Display';
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: normal;
  color: #000000;
  margin: 1px 0 1px 1px;
}

.challenge-status__body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  margin: 0;
  max-width: 180px;
  white-space: break-spaces;
}

.challenge-highlighted {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  color: #fb6a66;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
}

.challenge-address-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 17px;
}

.challenge-address-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 17px;
}

.challenge-address-container span {
  display: block;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  color: #000;
  margin: 0 auto 4px auto;
}

.tablet-links {
  margin-top: 20px;
}

.tablet-links .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 850px;
  align-items: flex-start;
  box-sizing: border-box;
}

.tablet-links .buttons-container .mybox-btn {
  margin: 0 15px;
}

.mybox-membership-button {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  margin-bottom: 12px;
  padding: 15px 16px;
  border: none;
  background-color: #ff5b5d;
  line-height: 18px;
  font-size: 16px;
  text-decoration: none;
  box-sizing: border-box;
  overflow: visible;
  cursor: pointer;
}

.mybox-membership-button:hover {
  background-color: #fa9c98;
}

.mybox-membership-button:active {
  background-color: #c75552;
}

.mybox-button {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: center;
  color: #000000;
  width: 100%;
  margin-bottom: 12px;
  padding: 15px 16px;
  border: solid 1px #000000;
  background-color: #ffffff;
  text-decoration: none;
  box-sizing: border-box;
  overflow: visible;
  height: 48px;
  cursor: pointer;
}

.mybox-button:last-child {
  margin-bottom: 18px;
}

.mybox-button:hover {
  border: solid 1px #d0d0d0;
}

.mybox-button:active {
  border: solid 1px #9b9b9b;
}

.review-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .status-details-desktop {
    margin: 50px auto 0 auto !important;
    justify-content: space-between;
    max-width: 857px;
  }
  .status-details-desktop-not-sub {
    margin: 50px auto 0 auto !important;
    justify-content: space-between;
    max-width: 857px;
  }
  .challenge-status-desktop {
    justify-content: center;
  }
  .tablet-links {
    width: 100%;
    margin: 20px auto;
  }
  .tablet-links .buttons-container {
    margin: 0 auto;
    width: 857px;
  }
  .buttons-container .fff-btn-primary-black-outline {
    width: 250px;
  }
  .with-address .challenge-status__details-desktop {
    max-width: 580px;
  }
}

@media screen and (min-width: 1024px) {
  .mybox-container {
    flex-direction: row;
    align-items: flex-start;
    max-width: 1200px;
    padding: 24px 0;
    margin: 0 auto;
  }
  .mybox-image {
    width: 159px;
    height: 108px;
  }
  .buttons-container {
    flex: 2 1;
    align-items: flex-end;
  }
  .mybox-btn {
    max-width: 282px;
  }
  .status-container {
    flex: 2;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 25px;
  }
  .status-container h3 {
    font-size: 36px;
    line-height: 42px;
    font-weight: bold;
    text-align: left;
    margin: 0;
    white-space: pre;
  }
  .status-container h3 span {
    text-align: left;
    margin: 1px 0 2px 0;
  }
  .status-container__body {
    margin: 7px 0 0;
    text-align: left;
    max-width: unset;
  }
  .status-container .track {
    text-align: left;
  }
  .status {
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 258px;
    margin: 1px 0 0 24px;
  }
  .address-container {
    flex: 1;
    align-items: flex-start;
    height: 122px;
    margin-top: 25px;
  }
  .divider {
    margin: 0 auto;
  }
  .address-title {
    margin: 2px auto 5px 30px;
  }
  .address-details {
    margin: 4px auto 0 30px;
  }
  .address-piece {
    text-align: left;
    margin: 0 auto 0 30px;
  }
  .address-container > .highlighted {
    margin: 0 auto 0 30px;
  }

  .challenge-status-container {
    flex-direction: row;
    align-items: flex-start;
  }
  .challenge-status {
    flex-direction: column;
  }
  .status-details-desktop {
    display: flex;
    margin: 30px auto 0 0;
    width: 100%;
    max-width: 857px;
  }

  .status-details-desktop-not-sub {
    display: flex;
    margin: 30px auto 0 0;
    width: 100%;
  }

  .challenge-status-container-desktop span {
    display: block;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 12px;
    font-weight: 800;
    line-height: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: left;
    color: #000;
    margin: 0 0 18px 0;
  }
  .challenge-status__details-desktop h3 {
    font-family: 'Playfair Display';
    font-size: 36px;
    font-weight: bold;
    line-height: 42px;
    letter-spacing: normal;
    color: #000000;
    margin: 0 0 7px 0;
  }
  .challenge-status__body-desktop {
    font-family: 'Nunito Sans', sans-serif;
    line-height: 24px;
    letter-spacing: normal;
    text-align: left;
    color: #363636;
    margin: 0;
    margin-top: 7px;
    white-space: break-spaces;
    font-size: 16px;
    font-weight: normal;
  }
  .challenge-status__body-desktop.wizmoMode {
    font-family: 'Nunito Sans', sans-serif;

    font-size: 18px;
    font-weight: bold;
  }
  .challenge-status__body-desktop i {
    margin-right: 6px;
  }
  .challenge-status__body-desktop i > svg {
    height: 18px;
    width: 22px;
  }
  .address-details-desktop {
    margin-left: 101px;
  }
  .address-details-desktop .challenge-address-container {
    align-items: flex-start;
  }
  .address-details-desktop .address-title {
    margin: 0px 0 9px 0;
  }
  .address-details-desktop .address-details {
    margin: 0;
  }
  .address-details-desktop .address-piece {
    margin: 0;
  }
  .mybox-membership-button {
    width: 100%;
    max-width: 282px;
    box-sizing: border-box;
  }
  .mybox-button {
    width: 100%;
    max-width: 282px;
    box-sizing: border-box;
  }
  .highlighted {
    text-align: left;
  }
}

@media screen and (max-width: 1250px) {
  .address-container {
    flex: 1 0;
  }
  .status {
    min-width: unset;
  }
  .status h3 {
    margin-top: 23px;
  }
  .highlighted {
    text-align: center;
  }
}
