@value shared: "../../../shared/shared.module.css";
@value sm from shared;

.modal {
  position: relative;
}

.modalPortal {
  background: none;
  border: none !important;
}

.overlay {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  z-index: 3;
}

.contentContainer {
  background-color: white;
  position: fixed;
  bottom: 0;
  right: 0;
  border-radius: 6px;
  width: 100%;
  height: auto;
  z-index: 4;
}

.headerContainer {
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  padding: 18px 12px;
  border-radius: 6px 6px 0 0;
  font-size: 16px;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 700;
  font-family: 'Nunito Sans', sans-serif;
  align-items: center;
}

.headerContainer button {
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  margin: 0;
  background: none;
  border: none;
}

.itemContainer {
  background-color: white;
  display: flex;
  padding: 24px 12px 0;
  gap: 12px;
  justify-content: space-between;
}

.imageAndDescriptionContainer {
  display: flex;
  gap: 12px;
}

.imageAndDescriptionContainer img {
  width: 62px;
  height: 62px;
}

.itemDescription p {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
  padding: 0;
}

.itemDescription p:nth-of-type(2) {
  margin-top: 6px;
}

.price {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  padding: 0;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  padding: 16px 12px 0;
  gap: 16px;
  align-items: center;
  background-color: white;
}

.buttonsContainer a {
  color: #fff;
}

.disclaimer {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-family: 'Nunito Sans', sans-serif;
  padding: 8px 12px 16px !important;
  margin: 0;
  background-color: white;
  border-radius: 0px 0px 6px 6px;
}

@media (min-width: sm) {
  .contentContainer {
    position: absolute;
    max-width: 375px;
    width: 375px;
    right: 0;
    top: 50px;
  }
}
