.fff-shared-footer-container {
  background-color: #ffffff;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Sans', sans-serif;
  border-top: 1px solid #e8e8e8;

  .fff-shared-footer-container-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .fff-shared-footer-links-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .fff-logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 32px 0;

      img {
        max-width: 100%;
        width: 200px;
        height: auto;
        object-fit: contain;
      }
    }

    .fff-shared-footer-social-media-container {
      padding: 8px 0;
    }
  }
}

.bottom-legal-footer-container .copyright-text {
  white-space: normal;
}

@media (min-width: 768px) {
  .fff-shared-footer-container .fff-shared-footer-container-top {
    align-items: flex-start;
    padding: 40px;
    gap: 40px;

    .fff-shared-footer-links-container {
      flex-direction: column;
      gap: 32px;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .fff-logo-container {
      padding: 0;
    }

    .fff-shared-footer-social-media-container {
      align-self: center;
      padding: 0;
    }
  }
}

@media (min-width: 1200px) {
  .fff-shared-footer-container .fff-shared-footer-container-top {
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    width: inherit;
    margin: 0 auto;
    max-width: 1200px;
    padding: 40px;

    .fff-shared-footer-links-container {
      flex-direction: row;
      gap: 8px;

      .fff-shared-footer-social-media-container {
        align-self: flex-start;
      }
    }
  }
}
