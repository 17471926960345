@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,700|Playfair+Display:700&display=swap');

.pmContainer {
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100vw;
  overflow: auto;
  z-index: 900;
}

.pmContainer > .content {
  margin: 10% auto 12px auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 32px 13px 8px 13px;
  background-color: white;
  border-radius: 6px;
  left: 0;
  right: 0;
}

.pmContainer > .content > .close {
  position: relative;
}

.pmContainer > .content > .close img {
  position: absolute;
  box-sizing: border-box;
  font-family: 'Nunito Sans', sans-serif;
  display: block;
  text-decoration: none;
  border-radius: 12px;
  padding-top: 4px;
  padding-left: 9px;
  right: -4px;
  top: -27px;
  cursor: pointer;
}

.pmContainer > .content > .pmTitle {
  margin-top: 11px;
  font-family: 'Playfair Display', sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 42px;
  text-align: center;
}

.pmContainer > .content > .pmSubtitle {
  margin-top: 8px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  text-align: center;
}

@media screen and (max-width: 824px) {
  .pmContainer > .content {
    margin: 96px 12px 70px 12px;
  }

  .pmContainer > .content > .pmTitle {
    margin-top: 3px;
    text-align: center;
    font-size: 30px;
    line-height: 36px;
    padding: 0 20px;
  }

  .pmContainer > .content > .pmSubtitle {
    margin-top: 12px;
  }
}
