.collapsible-panel {
  margin-bottom: -2px;
}

.collapsible-panel__header {
  display: flex;
  padding: 18px 20px 13px 0;
  justify-content: space-between;
}

.collapsible-panel__header::after {
  cursor: pointer;
  font-family: 'Font Awesome 5 Pro';
  font-size: 10px;
  font-weight: 300;
  color: #363636;
  content: '\f054';
  transform: rotate(90deg);
  transform-origin: center center;
  transition: transform 0.6s;
  line-height: 10px;
  height: 10px;
}

.collapsible-panel__body {
  overflow: hidden;
  transition: all 0.6s;
}

.collapsible-panel__header h4 {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
  max-width: 1200px;
  margin-left: 30px;
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: 2px;
}

.collapsible-panel.collapsed {
  margin-bottom: 0;
}

.collapsible-panel.collapsed .collapsible-panel__body {
  border-bottom: 1px solid #e8e8e8;
}

.collapsible-panel.collapsed .collapsible-panel__header::after {
  transform: rotate(0deg);
}

.collapsible-panel.desktop .collapsible-panel__header::after {
  content: '';
}

@media screen and (min-width: 1024px) {
  .collapsible-panel__header {
    padding-bottom: 0;
  }
}
