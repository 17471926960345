.leftMenuContainer {
  display: flex;
  flex: 1;
  flex-direction: column;

  .headerMenuContainer {
    display: flex;
    flex: 1;
    padding: 0;

    &>button {
      padding-left: 18px;
      margin: 0;
      min-width: 70px;
    }

    .logoContainer {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;

      .fffLogo {
        height: 30px;
        display: flex;
        align-items: center;

        img {
          height: 14px;
          width: 143px;
        }
      }
    }

    .rightContainer {
      display: flex;
      min-width: 70px;

      &.hidden {
        display: none;
      }
    }

    .joinBtn {
      color: #fb6a66;
      font-family: 'Nunito Sans';
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      border: none;
      background: none;
      text-transform: uppercase;
      padding: 0;
      cursor: pointer;
    }
  }
}

.sticky_cart {
  position: fixed;
  top: 7px;
  /* place at the upmost right of the 970px container */
  right: calc((100vw - 970px) / 2);
  margin-right: 0;
}

.mobileMenuContainer {
  display: flex;
}

.globalCart {
  margin-right: 14px;
  min-width: 40px;
}

@media screen and (min-width: 768px) {
  .leftMenuContainer {
    .headerMenuContainer {
      .logoContainer {
        .fffLogo {

          img {
            width: 162px;
            height: 16px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .leftMenuContainer {
    flex: 0;

    .headerMenuContainer {
      padding: 0;

      .logoContainer {
        margin-left: 0;

        .fffLogo {
          height: 47px;

          img {
            height: 20px;
            padding: unset;
            width: 199px;
          }
        }
      }

      .rightContainer {
        display: none;
      }

      .joinBtn {
        display: none;
      }
    }
  }

  .globalCart {
    display: none;
  }
}

@media screen and (min-width: 1200px) {

  /* Global Nav */
  .sticky_cart {
    /* place at the upmost right of the 1200px container */
    right: calc((100vw - 1200px) / 2);
  }
}
