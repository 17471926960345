$accentColor: #ff3b30;

.container {
  display: flex;
  height: 48px;
  background-color: #fff;
  border: 1px solid $accentColor;
  border-radius: 6px;
  border-left: 6px;
}

.content {
  display: flex;
  align-items: center;
  border-left: 6px solid $accentColor;
  border-radius: 6px;
  padding: 0 15px;
  width: 100%;
}

.leftContent {
  display: flex;
  flex: 1;
  align-items: center;

  i {
    color: $accentColor;
    font-size: 18px;
    margin-right: 12px;
  }

  p {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;

    .button {
      all: revert;
      background: none;
      border: none;
      color: $accentColor;
      cursor: pointer;
      display: contents;
      font-size: 12px;
      margin: 0;
      padding: 0;
    }
  }
}

@media screen and (min-width: xl) {
  .leftContent {
    p {
      line-height: 18px;
    }
  }
}
