.container {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  position: fixed;
  z-index: 3000;
  left: 0;
  top: 0;
}
