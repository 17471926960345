@use '../../../../sass/colors';
@use '../../../../sass/fonts';
@use '../../../../sass/mediaQueries';

.bestValueBtn {
  height: fit-content;
  border-radius: 21px;
  background-color: colors.$secondaryDark;
  font-family: fonts.$nunito, sans-serif;
  font-size: 12px;
  font-weight: fonts.$fontWeightBold;
  color: colors.$white;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  width: fit-content;
  text-wrap: nowrap;
  gap: 4px;
  align-items: center;
  display: flex;
  align-content: center;
  svg {
    width: 12px;
    height: 12px;
  }
}

@media (min-width: mediaQueries.$medium) {
  .bestValueBtn {
    display: flex;
    justify-content: center;
    height: 36px;
    padding: 0 16px;
    max-width: 150px;
    font-size: 16px;
    gap: 7px;
    width: 143px;
    svg {
      width: 18px;
      height: 20px;
    }
  }
}
