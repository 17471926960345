$small: 576px;
$medium: 768px;
$large: 992px;
$extraLarge: 1200px;

@mixin is-portrait() {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin is-landscape() {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin is-extra-small() {
  @media only screen and (max-width: 575px) {
    @content;
  }
}

@mixin is-small() {
  @media only screen and (min-width: $small) and (max-width: 767px) {
    @content;
  }
}

@mixin is-medium() {
  @media only screen and (min-width: $medium) and (max-width: 991px) {
    @content;
  }
}

@mixin is-large() {
  @media only screen and (min-width: $large) and (max-width: 1199px) {
    @content;
  }
}

@mixin is-extra-large() {
  @media only screen and (min-width: $extraLarge) {
    @content;
  }
}

@mixin is-mobile() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin is-desktop() {
  @media only screen and (min-width: $medium) {
    @content;
  }
}

@mixin is-below($value) {
  @media (max-width: $value) {
    @content;
  }
}

@mixin is-above($value) {
  @media (min-width: $value) {
    @content;
  }
}
