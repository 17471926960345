@use '../../../sass/colors';
@use '../../../sass/fonts';
@use '../../../sass/mediaQueries';

.container {
  flex-wrap: nowrap;
  justify-content: normal;
  display: flex;
  height: fit-content;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding-top: 33px;
  position: relative;
  padding-bottom: 20px;
  scroll-snap-type: x mandatory;
  gap: 17px;

  &::-webkit-scrollbar {
    background-color: transparent;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  > div {
    box-sizing: border-box;
    scroll-snap-align: center;
  }
}

.productCarousel {
  > div {
    flex: 0 0 40%;
  }
}

.reviewCarousel {
  gap: 12px;
  padding-top: 12px;
  > div {
    flex: 0 0 233px;
  }
}

@media (min-width: mediaQueries.$small) {
  .container {
    > div {
      flex: 0 0 33%;
    }
  }
}

@media (min-width: mediaQueries.$large) {
  .container {
    gap: 33px;
    padding-bottom: 34px;
  }

  .productCarousel {
    > div {
      flex: 0 0 22.6%;
    }
  }

  .reviewCarousel {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 33px;
    scroll-snap-type: none;
    box-sizing: border-box;
    > div {
      flex: 0 0 22.6%;
    }
  }
}

@media (min-width: mediaQueries.$extraLarge) {
  .productCarousel {
    > div {
      flex: 0 0 18.2%;
    }
  }

  .reviewCarousel {
    > div {
      flex: 0 0 18.2%;
    }
  }
}
