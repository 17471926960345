@value shared: '../shared.module.css';
@value fontPlayfair, fontNunitoSans, sm from shared;

.container {
  max-width: 100%;
}

.container img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  max-width: 100%;
  margin: 0;
}

.container .textContainer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container .textContainer h2 {
  font-family: fontPlayfair;
  font-weight: normal;
  font-size: 30px;
  margin: 0;
  align-self: flex-start;
  margin-bottom: 16px;
}

.container .textContainer .subtitle {
  font-size: 16px;
  margin: 0;
  font-family: fontNunitoSans;
  line-height: 24px;
}

.container .textContainer ul {
  margin-top: 16px;
  margin-left: 24px;
  font-family: fontNunitoSans;
  padding: 0;
  margin-bottom: 0;
}

.container .textContainer ul li {
  list-style-type: disc;
  color: #4a4a4a;
  font-size: 14px;
}

.container button {
  margin-top: 30px;
  margin-bottom: 16px;
  width: 100%;
  border-radius: 3px;
}

.container .textContainer .disclaimer {
  font-size: 9px;
  text-align: center;
  color: #4a4a4a;
  font-family: fontNunitoSans;
  margin: 0;
}

.container .textContainer .disclaimer a {
  font-weight: bold;
  text-decoration: none;
  color: inherit;
}

@media screen and (min-width: sm) {
  .container {
    max-width: 375px;
    margin: 18px 28px;
  }

  .container img {
    height: 180px;
    margin: 24px 0px;
  }

  .container .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .container .textContainer h2 {
    align-self: center;
    margin: 0;
  }

  .container .textContainer .subtitle {
    font-size: 18px;
    line-height: 27px;
  }

  .container .textContainer ul li {
    font-size: 16px;
  }

  .container .textContainer .disclaimer {
    font-size: 12px;
    width: 381px;
  }
}
