.container {
  background-color: #fdf1eb;
  padding: 12px;
  text-align: center;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.container h3 {
  margin: 0;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
}

.cartText,
.cartText p {
  font-size: 10px;
}

.cartText a {
  color: #fb6a66;
  font-weight: 700;
}

.cartText a:hover {
  color: #000;
}
