@value shared: '../shared.module.css';
@value xl from shared;
@value fontPlayfair, fontNunitoSans, colorDarkGrey from shared;

.container {
  background-color: #ede7e0;
  font-family: fontNunitoSans;
  padding: 33px 12px;
}

.container h2 {
  font-family: fontPlayfair;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  margin: 0;
  text-align: center;
}

.container ul.numberedList {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding-inline-start: 0;
}

.container ul.numberedList li {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  padding: 15px 0 17px;
  width: 351px;
}

.container ul.numberedList li:last-child {
  margin-bottom: 0;
}

.container ul.numberedList li .itemNumber {
  font-family: fontNunitoSans;
  font-weight: 400;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  min-width: 102px;
}

.container ul.numberedList li .itemDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-right: 18px;
}

.container ul.numberedList li .itemDescription strong {
  display: block;
  margin-bottom: 6px;
}

.container ul.numberedList li .itemDescription .itemTerms {
  color: colorDarkGrey;
  display: block;
  font-size: 12px;
  line-height: 18px;
  margin-top: 7px;
}

.container ul.numberedList li .itemDescription .itemTerms a {
  color: inherit;
  text-decoration: underline;
}

@media screen and (min-width: xl) {
  .container {
    padding: 37px 12px;
  }

  .container ul.numberedList li {
    border: 1px solid #686868;
    min-height: 106px;
    padding-right: 24px;
    width: 525px;
  }

  .container ul.numberedList li .itemNumber {
    min-width: 132px;
  }

  .container ul.numberedList li .itemTerms {
    width: auto;
  }
}
