@use '../../sass/colors';
@use '../../sass/fonts';
@use '../../sass/mediaQueries';

.container {
  display: flex;
  cursor: pointer;
  margin: 0 24px;
  border: 1px solid colors.$grayLight;
  border-radius: 6px;
  margin: 18px 0 0 0;
  padding: 12px;
  text-align: left;
  background-color: #fff;
  width: 100%;
  font-family: fonts.$nunito;
}
.container i {
  font-size: 20px;
  color: colors.$grayLight;
}

.selected {
  border: 2px solid colors.$primary;
  pointer-events: none;
  padding: 11px;
}
.selected i {
  color: colors.$primary;
}

.address {
  margin-left: 12px;
}
.address p {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
}

p.title {
  font-weight: bold;
  margin-bottom: 6px;
}

.suggested {
  color: colors.$secondaryDark;
  font-weight: bold;
}

@media screen and (min-width: mediaQueries.$medium) {
  .container {
    width: 340px;
  }
}
