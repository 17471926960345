/* Overrides for OneTrust CSS */
.bottom-legal-footer-container #ot-sdk-btn.ot-sdk-show-settings,
.bottom-legal-footer-container #ot-sdk-btn.optanon-show-settings {
  color: #222222;
  border: inherit;
  height: inherit;
  white-space: inherit;
  line-height: inherit;
  transition: inherit;
  padding: 0;
  background-color: inherit;

  &:hover {
    color: #fa9c98;
    text-decoration: none;
    background-color: inherit;
  }
}

.bottom-legal-footer-container {
  display: flex;
  flex-direction: column;
  background-color: #e8e8e8;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 12px 96px;

  .bottom-legal-footer-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    justify-content: center;
    align-items: center;

    .legal-footer-items {
      font-family: 'Nunito Sans', sans-serif;
      font-size: 9px !important;
      text-decoration: none;
      color: #222222;
      text-align: center;

      &:hover:not(.legal-footer-text-only) {
        transition: color 0.2s;
        opacity: 0.8;
      }
    }

    .legal-footer-text-only {
      cursor: default;
      background: none;
    }
  }

  .bottom-legal-footer-logo-content {
    display: flex;

    img {
      width: 24px;
    }
  }
}

@media (min-width: 768px) {
  .bottom-legal-footer-container {
    padding: 16px 0;
    gap: 16px;

    .bottom-legal-footer-content {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 16px;
      max-width: 608px;

      .legal-footer-items {
        font-size: 12px !important;
        line-height: 15px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .bottom-legal-footer-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;

    .bottom-legal-footer-content {
      justify-content: flex-start;
      max-width: max-content;
    }
  }
}
