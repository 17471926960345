.hero_banner_placeholder {
  background-color: #fde9d5;
  width: 100%;
  min-height: 200px;
  height: 30vw;
}

.homepage .status-details-desktop {
  margin-top: -30px;
}

.homepage .wrapperBanner h1 {
  margin: 0;
}
@media (min-width: 768px) {
  .homepage .wrapperBanner p {
    min-width: 530px;
  }
}
