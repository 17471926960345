@use '../../../../../../../../ui/fff-ui-shared/src/sass/fonts' as fonts;

.banner {
  display: flex;
  background-color: #000000;
  padding: 12px 0;
  justify-content: center;
  max-height: 44px;
}

.navLink {
  flex: 1;
  font-family: fonts.$nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  color: #ffffff;
  text-align: center;
  height: fit-content;
  border-right: 2px solid #ffffff;

  &:last-child {
    border-right: none;
  }
}

@media (min-width: 992px) {
  .banner {
    display: none;
  }
}
