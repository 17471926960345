.user-profile button {
  padding: 32px 18px;
}

.user-profile__item {
  width: 100%;
  display: flex;
}

.user-profile__item .avatar {
  margin-right: 18px;
}

.user-profile__item__detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
}

.user-profile__item__detail__name {
  display: flex;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: left;
}

.subscription-state {
  color: #ffffff;
  font-family: 'Nunito Sans';
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 1.5px;
  line-height: 12px;
  border-radius: 3px;
  background-color: #4ebd41;
  padding: 3px 6px;
}

.subscription-state.canceled {
  background-color: #8a8a8a;
}

.subscription-state.expired {
  background-color: #c75552;
}

.user-profile__item__detail__subscription {
  font-family: 'Nunito Sans';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-transform: capitalize;
  font-weight: 400;
}

.user-profile__item__detail__subscription i {
  color: #fb6a66;
  margin-right: 6px;
  font-weight: 700;
}

.user-profile__item__detail__subscription p {
  margin: 0;
}

.lowerCase {
  text-transform: lowercase;
}

@media screen and (min-width: 992px) {
  .profile-button {
    position: relative;
    display: flex;
    width: 42px;
    padding: 0;
    margin: 0 0 0 24px;
    background: none;
    border: none;
    letter-spacing: normal;
    outline: none;
  }
  .profile-button .sub-menu {
    visibility: hidden;
    display: block;
    transition: visibility 0.01s ease-out 0.01s;
    position: absolute;
    top: 64px;
    left: 16px;
    box-sizing: border-box;
    width: 250px;
    margin-left: -125px;
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }
  .profile-button:hover .sub-menu {
    visibility: visible;
    transition: visibility 0.01s ease-in 0s;
  }
  .profile-button .sub-menu ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-width: 250px;
    height: auto;
    background-color: #fff;
    padding: 6px 0 !important;
    margin: 0;
  }
  .profile-button .sub-menu::before {
    content: '';
    top: -24px;
    padding-top: 12px;
    left: calc(50% - 8px);
    display: block;
    z-index: 3;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #f9f9f9;
    filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.1));
  }
  .profile-button .sub-menu ul li {
    display: block;
    box-sizing: border-box;
    width: 250px;
    max-height: 42px;
    background-color: #fff;
    box-sizing: border-box;
    text-align: left;
    padding: 0;
  }
  .profile-button .sub-menu ul li a {
    width: 250px;
    text-align: left;
    box-sizing: border-box;
    text-decoration: none;
    color: #222222;
    font-family: 'Nunito Sans';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    padding: 12px 18px;
  }
  .profile-button .sub-menu ul li:hover {
    background-color: #eee;
  }
  .profile-button .sub-menu ul li:hover a {
    color: #222;
  }
  .profile-button .sub-menu .sub-menu__label {
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 20px 18px 18px 18px;
  }
  .profile-button .sub-menu .sub-menu__label .fa-angle-down {
    display: none;
  }
  .user-profile__item__detail__subscription {
    display: flex;
    align-items: baseline;
  }
  .profile-button .user-profile__item__detail__subscription i {
    display: block;
    color: #fb6a66;
    font-size: 14px;
    font-weight: 700;
    width: 14px;
  }
  .profile-button .sub-menu .user-profile__item__detail__subscription i {
    color: #fb6a66;
  }
}
