.codeTagContainer {
  margin-top: 12px;
  border-radius: 3px;
  background-color: rgba(198, 247, 238, 0.5);
  color: #000;
  font-family: 'Nunito Sans';
  font-size: 12px;
  line-height: 18px;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.codeTagContainer .tagIconContainer {
  color: #000;
  font-size: 18px;
  font-weight: 900;
  margin-right: 6px;
}

.codeTagContainer .codeText {
  text-transform: uppercase;
  word-break: break-word;
}

.codeTagContainer .closeContainer {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0 0 0 12px;
}
