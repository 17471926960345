@value shared: '../shared.module.css';
@value fontNunitoSans, colorPink, colorSilver from shared;

.container {
  font-family: fontNunitoSans;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-inline-start: 0;
}

.container .node {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 106px;
}

.container .node::before {
  content: '';
  width: 35px;
  border-bottom: 2px solid colorPink;
  position: absolute;
  top: 23px;
  left: 0;
}
.container .node::after {
  content: '';
  width: 35px;
  border-bottom: 2px solid colorPink;
  position: absolute;
  top: 23px;
  right: 0;
}
.container .node.current::after {
  border-color: colorSilver;
}
.container .node.inactive::before,
.container .node.inactive::after {
  border-color: colorSilver;
}

.container .node:first-child::before {
  border: 0;
}
.container .node:last-child::after {
  border: 0;
}

.container .node .nodeNumber {
  color: #fff;
  font-family: fontNunitoSans;
  font-size: 16px;
  text-align: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
}
.container .node.active .nodeNumber,
.container .node.current .nodeNumber {
  background-color: colorPink;
  border: 6px solid #f2e3f0;
  box-sizing: content-box;
}
.container .node.inactive .nodeNumber {
  background-color: colorSilver;
  border: 6px solid #f6f6f6;
  box-sizing: content-box;
}

.container .node .nodeLabel {
  color: #000;
  font-family: fontNunitoSans;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}
