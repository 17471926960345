.formErrorBanner {
  font-family: 'Nunito Sans', sans-serif;
  background: #ff5959;
  text-align: center;
  color: #fff;
  padding: 5px 20px;
  margin: 0 0 5px;
  font-size: 14px;
  font-weight: 700;
  a {
    color: #fff;
  }
}
