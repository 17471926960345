.orderSummaryContainer {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 10px auto;
  width: 100%;
}

.orderSummaryContainer .annualBoxDetailText {
  font-size: 12px;
  line-height: 15px;
  color: #000;
  opacity: 0.6;
}
.orderSummaryContainer .productName {
  max-width: 240px;
}
.orderSummaryContainer .productName i {
  margin-right: 6px;
}
.orderSummaryContainer .introductoryOffer {
  font-size: 9px;
  font-weight: 800;
  letter-spacing: 0.9px;
  line-height: 15px;
  text-transform: uppercase;
  background-color: #fdf1eb;
  border-radius: 3px;
  padding: 2px 8px;
  margin: 6px 0 10px 10px;
}
.orderSummaryContainer .membershipDescription {
  margin: 6px 0 0 10px;
}
.orderSummaryContainer .taxNote {
  font-size: 12px;
}
.orderSummaryContainer .taxesValue {
  color: #666;
}
.orderSummaryContainer .savingsLineItem {
  border-bottom: 1px solid #eee;
  display: flex;
  margin: 6px 0;
  width: auto;
  justify-content: center;
  line-height: 1.5;
  padding-bottom: 16px;
}
.orderSummaryContainer .savingsLineItem svg {
  fill: #ccc;
  margin-top: 1px;
}
.orderSummaryContainer .savingsLineItemText {
  margin-right: 6px;
  width: auto;
}

.orderSummaryContainer .savings {
  color: #ff5b5d;
  margin-left: 2px;
}

.savingsTooltip {
  text-align: left;
  line-height: 1.8;
}

.noContainer {
  border: none;
}

@media screen and (min-width: 768px) {
  .orderSummaryContainer {
    align-self: flex-start;
    margin: 0;
    width: auto;
  }
}
