@use '../../sass/mediaQueries' as *;

.container {
  max-width: 400px;
  padding: 24px;
  font-family: 'Nunito Sans';
  box-sizing: border-box;

  h1 {
    margin: 0;
    padding-bottom: 16px;
    font-family: 'Playfair Display';
    text-align: center;
    font-size: 24px;
    font-weight: 400;
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
  }

  span {
    font-size: 12px;
    color: #666;
    margin-top: 8px;
    margin-bottom: 24px;
    display: block;
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    button {
      width: 164px;
      font-size: 16px;
      border-radius: 3px;
    }
  }
}

@media screen and (min-width: $large) {
  .container {
    padding: 24px 30px;
  }
  .buttonsContainer {
    button {
      font-size: 18px;
    }
  }
}
