@use '../../../sass/colors';
@use '../../../sass/fonts';
@use '../../../sass/mediaQueries';

/* SeasonalPlan */
.planSubTitle {
  color: colors.$black;
  font-family: fonts.$nunito, sans-serif;
  font-size: 9px;
  font-weight: fonts.$fontWeightBold;
  text-wrap: nowrap;
  opacity: 0.6;
  padding: 7px 0;
  margin: 0;
}

.planDisclaimer {
  color: colors.$grayDark;
  font-family: fonts.$nunito, sans-serif;
  font-size: 9px;
  margin: 12px 0;
}

@media (min-width: mediaQueries.$medium) {
  .planSubTitle {
    font-size: 14px;
    padding: 8px 0;
  }
  .planDisclaimer {
    font-size: 12px;
    margin: 12px 0;
  }
}
