.banner-container {
  width: 100%;
  height: 432px;
  object-fit: contain;
  background-image: url('./images/diversity-mobile-bg@2x.jpg');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 12px;
}

.title {
  margin: 0 0 1px;
  font-family: 'Playfair Display';
  font-size: 36px;
  font-weight: bold;
  line-height: 42px;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.subtitle {
  margin: 0 0 11px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.get-started {
  width: 351px;
  height: 48px;
  margin: 0 0 13px 0;
  padding: 15px 48px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #ff5b5d;
  border: none;
  cursor: pointer;
}

.get-started:hover {
  background-color: #fa9c98;
}

.get-started:active {
  background-color: #c75552;
}

.get-started:disabled {
  background-color: #d0d0d0;
}

.details {
  margin: 0 27px 0 30px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.hiw-container {
  width: 100%;
  padding: 24px 0 0 0;
  background-color: #ffffff;
}

.rangeme-title {
  margin: 0 50px 36px;
  font-family: 'Playfair Display';
  font-size: 36px;
  font-weight: normal;
  line-height: 42px;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 67px;
  height: 67px;
  margin: 0 auto 23px;
  border: solid 1px #fa9c98;
  border-radius: 50%;
  background-color: #fff;
  color: #fa9c98;
  font-family: 'Playfair Display';
  font-size: 42px;
  line-height: 48px;
  text-align: center;
}

.step-number span {
  margin-top: -10px;
}

.step-title {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.step-details {
  margin: 7px auto 37px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  max-width: 250px;
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px 23px;
}

.info-title {
  margin: 36px 0 30px;
  font-family: 'Playfair Display';
  font-size: 36px;
  font-weight: normal;
  line-height: 42px;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.info-details-title {
  margin: 0 0 13px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: normal;
  color: #000;
}

.info-details {
  margin: 0 0 29px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #000;
}

.divider {
  width: 100%;
  max-width: 1200px;
  height: 1px;
  background-image: linear-gradient(
    to left,
    #ffffff,
    #dddddd 81%,
    #d0d0d0 48%,
    #e6e6e6 17%,
    #ffffff 0%
  );
}

@media screen and (min-width: 768px) {
  .banner-container {
    background-image: url('./images/diversity-bg@2x.jpg');
  }
  .text-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 73px 0 0 0;
  }
  .title {
    margin: 0 0 6px;
    font-size: 42px;
    line-height: 48px;
  }
  .subtitle {
    margin: 0 0 17px;
    max-width: 480px;
  }
  .get-started {
    width: 100%;
    max-width: 215px;
    margin: 0 0 15px 0;
  }
  .details {
    width: 100%;
    max-width: 294px;
    margin: 0 27px 0 27px;
  }

  .hiw-container {
    padding: 30px 0 0 0;
  }
  .rangeme-title {
    margin: 0 0 37px;
  }
  .steps {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    max-width: 1070px;
  }

  .info-title {
    margin: 37px 0 59px;
    font-size: 42px;
    font-weight: normal;
    line-height: 48px;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    max-width: 490px;
  }
  .details-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    max-width: 1200px;
  }
  .detail {
    max-width: 384px;
  }
  .info-details {
    max-width: 384px;
  }
}
