.content {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;

  @media (min-width: 768px) {
    flex-wrap: nowrap;

    & input,
    & select {
      max-width: 204px;
    }
  }
}
