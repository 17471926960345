@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes wave {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.skeleton {
  background-color: rgba(0, 0, 0, 0.11);
  width: 100%;
  height: 1.2em;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.text,
.rectangular {
  border-radius: 4px;
}

.circular {
  border-radius: 50%;
}

.animation_pulse {
  animation: pulse 1.5s ease-in-out 0.5s infinite;
}

.animation_wave {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: wave 1.6s linear 0.5s infinite;
  }
}
