@value shared: '../shared.module.css';
@value fontPlayfair, lg from shared;

.container {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 620px;
  padding: 36px 12px 18px;
}

.container .wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
}

.container .wrapper .titleBackground {
  background-color: #ede7e0;
  color: #000;
  margin-bottom: 18px;
  text-align: center;
}

.container .wrapper .titleBackground h2 {
  font-family: fontPlayfair;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 48px 22px;
}

@media screen and (min-width: lg) {
  .container {
    min-height: unset;
    padding: 88px 0;
    width: 100%;
  }

  .container .wrapper {
    background-color: #ede7e0;
    max-width: 1038px;
  }

  .container .wrapper .titleBackground {
    margin-bottom: 0;
  }

  .container .wrapper .titleBackground h2 {
    margin: 39px 54px 0;
  }

  .container .wrapper button {
    margin: 24px 0 39px;
  }
}
