.loading-icon-container {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: calc(100% - 60px);
  z-index: 1000;
}

.loading-icon {
  animation-name: loading;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  animation-delay: 0s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  width: 100px;
  height: 100px;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

@media screen and (min-width: 1024px) {
  .loading-icon-container {
    height: calc(100% - 72px);
  }
}
