.header {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-top: 13px;
  padding-bottom: 18px;
  margin-bottom: 22px;
}

.fffLogo {
  width: 147px;
}
