.react-joyride__tooltip .icon {
  font-size: 24px;
}

.react-joyride__tooltip .onboardingRedIcon {
  color: #fa9c98;
}

.react-joyride__tooltip .progressIndicator {
  color: #9b9b9b;
  text-align: right;
  font-family: 'Nunito Sans';
  font-size: 12px;
  line-height: 24px;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.react-joyride__tooltip .onboardingGreenIcon {
  color: #9dd321;
}
