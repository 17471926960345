.timeline__node {
  display: flex;
}

.timeline__node__point {
  border: solid 2px #d0d0d0;
  background-color: #ffffff;
  display: block;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 12px;
}

.timeline__node__point.past {
  border: solid 6px #9b9b9b;
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
}

.timeline__node__point.active {
  border: solid 6px #d6e986;
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
}

.timeline__node__label {
  margin-left: 19px;
  margin-top: 4px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  letter-spacing: initial;
}

a.timeline__node__label {
  margin-left: 19px;
  margin-top: 4px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  letter-spacing: initial;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.timeline__node__current-step-message {
  opacity: 0.6;
  color: #000000;
  font-family: 'Nunito Sans';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  margin-top: 7px;
  overflow: hidden;
}

.timeline__node__current-step-message a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

.timeline__node-box {
  padding: 11px 18px;
}

.challenge-timeline__node {
  display: flex;
  align-items: flex-start;
}

.challenge-timeline__node-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.challenge-timeline__node-cursor {
  cursor: pointer;
  color: #000;
  text-decoration: none;
}

.challenge-timeline__node-box {
  display: flex;
  padding: 14.5px 18px;
}

.shorter-timeline .challenge-timeline__node-box-desktop {
  padding: 18px 77px 0;
}

.challenge-timeline__node-box-desktop {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 18px 52px 0;
}

.challenge-timeline__node-box-desktop:first-child {
  padding-left: 0;
}

.challenge-timeline__node-box-desktop:last-child {
  padding-right: 0;
}

.shorter-timeline
  .challenge-timeline__node-box-desktop
  .challenge-timeline__node-box__line-desktop {
  width: 153px;
  left: 133px;
}

.challenge-timeline__node-box-desktop .challenge-timeline__node-box__line-desktop {
  width: 100px;
  left: 103px;
  margin-left: 0;
}

.challenge-timeline__node-box-desktop:first-child .challenge-timeline__node-box__line-desktop {
  left: 52px;
  margin-left: 0;
}

.challenge-timeline__node__label {
  margin: 0 0 0 20px;
  padding-top: 5px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  letter-spacing: initial;
}

.challenge-timeline__node__label-desktop {
  margin: 12px 0 0 0;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  letter-spacing: initial;
  max-width: 56px;
  text-align: center;
}

.challenge-timeline__node.active .challenge-timeline__node__label {
  font-weight: 800;
}

.timeline__node-box.colored {
  border-radius: 6px;
  border: solid 2px #faccca;
  background-color: #fff8f5;
  padding: 9px 18px 1px 18px;
  margin-top: 2px;
  margin-bottom: 4px;
}

.challenge-timeline__node-box.colored {
  border-radius: 6px;
  border: solid 2px #faccca;
  background-color: #fff8f5;
  padding: 9px 18px 1px 18px;
  margin-top: 2px;
  margin-bottom: 4px;
}

.timeline__node-box.colored .timeline__node__point {
  margin-left: -2px;
}

.challenge-timeline__node-box.colored .timeline__node__point {
  margin-left: -2px;
}

.timeline__node-box.colored .timeline__node {
  margin-bottom: 24px;
}

.challenge-timeline__node-box.colored .timeline__node {
  margin-bottom: 24px;
}

.timeline__node-box.colored .timeline__node:last-child {
  margin-bottom: 0;
}

.challenge-timeline__node-box.colored .timeline__node:last-child {
  margin-bottom: 0;
}

.timeline__node-box.colored .timeline__line {
  margin-top: -19px;
  margin-left: 9px;
  height: 25px;
}

.challenge-timeline__node-box.colored .timeline__line {
  margin-top: -19px;
  margin-left: 9px;
  height: 25px;
}

.timeline__node-box__label {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 9px;
  font-weight: 800;
  line-height: 12px;
  letter-spacing: 2px;
  margin-left: 42px;
  margin-bottom: 6px;
}

.timeline__line {
  position: absolute;
  border: dashed 1px #d0d0d0;
  margin-top: 6px;
  margin-left: 11px;
  height: 28px;
  z-index: 2;
}

.timeline__line.solid,
.timeline__node-box__line.solid {
  border-style: solid;
  border-color: #9b9b9b;
}

.timeline__line.solid,
.challenge-timeline__node-box__line.solid {
  border-style: solid;
}

.challenge-timeline__line {
  position: absolute;
  border: dashed 1px #d0d0d0;
  margin-top: 6px;
  margin-left: 11px;
  height: 28px;
  z-index: 2;
}

.challenge-timeline__line,
.challenge-timeline__node-box__line-desktop.solid {
  border-style: solid;
}

.timeline__node-box__line {
  position: absolute;
  border: dashed 1px #d0d0d0;
  margin-top: -19px;
  margin-left: 9px;
  height: 11px;
  z-index: 2;
}

.challenge-timeline__node-box__line {
  border: dashed 1px #d0d0d0;
  z-index: 2;
  margin-right: -2px;
  transform: translate(11px, 31px);
  padding-bottom: 19px;
  margin-bottom: 10px;
}

.challenge-timeline__node-box__line-desktop {
  position: absolute;
  border: dashed 1px #d0d0d0;
  margin-top: 11px;
  margin-left: 11px;
  width: 122px;
  height: 0;
  z-index: 2;
}

.timeline__line.solid,
.challenge-timeline__node-box__line.solid {
  border-style: solid;
}

.timeline__node-box:last-child {
  margin-top: 14px;
}

.challenge-timeline__node-box:last-child {
  margin-top: 0;
}

.timeline__node-box:nth-last-child(2) .timeline__line:last-child {
  height: 22px;
}

.challenge-timeline__node-box:nth-last-child(2) .timeline__line:last-child {
  height: 22px;
}

.timeline__node-box:last-child .timeline__line:last-child {
  display: none;
}

.challenge-timeline__node-box:last-child .timeline__line:last-child {
  display: none;
}

.box-timeline {
  margin-top: 13px;
  margin-bottom: 5px;
  position: relative;
  width: 100%;
}

.challenge-divider {
  height: 1px;
  width: 100%;
  margin: -1px 0 18px;
  background-image: linear-gradient(
    to left,
    #ffffff,
    #dddddd 19%,
    #d0d0d0 52%,
    #e6e6e6 83%,
    #ffffff
  );
}

.challenge-last-divider {
  height: 1px;
  width: 100%;
  margin: 12px 0 18px;
  background-image: linear-gradient(
    to left,
    #ffffff,
    #dddddd 19%,
    #d0d0d0 52%,
    #e6e6e6 83%,
    #ffffff
  );
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .long .challenge-timeline__node-box__line {
    height: 14px;
    margin-top: 6px;
  }

  .very-long .challenge-timeline__node-box__line {
    height: 26px;
    margin-top: -7px;
  }
}

@media screen and (min-width: 1024px) {
  .timeline {
    display: flex;
    flex-direction: row;
    margin-top: 18px;
    margin-left: -30px;
  }

  .timeline__node {
    flex-direction: column;
  }

  .timeline__node-box,
  .timeline__node-box:last-child {
    margin-top: 15px;
  }

  .timeline__node-box:last-child {
    margin-left: 10px;
  }

  .timeline__node-box.colored {
    display: flex;
    flex-direction: column;
    width: 208px;
    height: 126px;
    box-sizing: border-box;
    padding: 10px 7px;
    margin-left: 22px;
    margin-right: 20px;
    overflow: hidden;
  }

  .timeline__node-box.colored .timeline__node__label {
    margin-left: -34px;
  }

  .timeline__node__label {
    margin-left: -31px;
    margin-top: 37px;
    width: 87px;
    text-align: center;
    position: absolute;
    padding: 0 10px;
    box-sizing: border-box;
    max-height: 45px;
    overflow: hidden;
  }

  a.timeline__node__label {
    margin-left: -31px;
    margin-top: 37px;
    width: 87px;
    text-align: center;
    position: absolute;
    padding: 0 10px;
    box-sizing: border-box;
    max-height: 45px;
    overflow: hidden;
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .timeline__node-box__container {
    display: flex;
    justify-content: space-between;
    padding: 18px 30px 0 20px;
  }

  .timeline__node-box__label {
    margin: 0;
    width: 100%;
    text-align: center;
    white-space: nowrap;
  }

  .timeline__node-box__line,
  .timeline__line {
    transform: translate(19px, 4px);
    width: 82px;
    height: 0 !important;
  }

  .timeline__node-box.colored .timeline__line {
    transform: translate(137px, 29px);
  }

  .timeline__node-box.colored .timeline__node-box__line {
    width: 80px;
    transform: translate(20px, 29px);
  }

  .box-timeline {
    display: flex;
    align-items: flex-start;
    max-width: 700px;
    margin: 18px 0 0 34px;
  }

  .challenge-timeline__node-box.colored .timeline__node-box__line {
    width: 80px;
    transform: translate(20px, 29px);
  }

  .timeline__node-box.colored .challenge-timeline__node-box__line {
    width: 80px;
    transform: translate(20px, 29px);
  }

  .challenge-timeline__node-box.colored .challenge-timeline__node-box__line {
    width: 80px;
    transform: translate(20px, 29px);
  }

  .challenge-timeline__node-box.colored .timeline__line {
    transform: translate(137px, 29px);
  }

  .challenge-timeline__node-box__line .timeline__line {
    transform: translate(19px, 4px);
    width: 82px;
    height: 0 !important;
  }

  .challenge-timeline__node-box.colored .timeline__node__label {
    margin-left: -34px;
  }

  .challenge-timeline__node-box.colored {
    display: flex;
    flex-direction: column;
    width: 208px;
    height: 126px;
    box-sizing: border-box;
    padding: 10px 7px;
    margin-left: 22px;
    margin-right: 20px;
    overflow: hidden;
  }

  .challenge-timeline__node-box:last-child {
    margin-left: 10px;
  }

  .timeline__node__current-step-message {
    display: none;
  }
}
