.passwordTooltip {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  position: absolute;
  background: #fff;
  padding: 15px;
  margin: 10px 0 0;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
  top: 80px;
  max-width: 314px;
}

.passwordTooltip .passwordTooltipTitle {
  font-weight: bold;
  margin: 0;
}

.passwordTooltip .passwordTooltipRow {
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 6px 0 0;
}

.passwordTooltip .passwordTooltipCheck {
  width: 14px;
  height: 14px;
  margin: 0px 9px 0px 0;
  border: solid 1px #d0d0d0;
  background-color: #ffffff;
  border-radius: 50%;
}

.passwordTooltip .passwordTooltipCheckValid {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.passwordTooltip .passwordTooltipTip {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 1.29;
  margin: 0;
}

.passwordTooltip .triangleWithShadow {
  width: 40px;
  height: 20px;
  position: absolute;
  overflow: hidden;
  bottom: 162px;
  left: 6px;
  padding: 0 3px;
}

.passwordTooltip .triangleWithShadow:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(135deg);
  top: 9px;
  left: 30%;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.2);
}
