@use '../../sass/mediaQueries';

.addMore {
  margin-top: 4px;
  margin-bottom: 12px;

  button {
    width: 100%;
    border-radius: 3px;

    i {
      margin-right: 9px;
    }
  }
}

@media screen and (min-width: mediaQueries.$large) {
  .addMore {
    button {
      max-width: 299px;
    }
  }
}
