.ptContainer {
  flex-direction: column;
  grid-template-columns: 1fr;
  gap: 0;
  border: 0;
  padding: 0;
  width: 100%;
}

.billingOption {
  width: 100%;
}

.billingOptionDisabled {
  filter: grayscale(100%);
  opacity: 0.4;
  cursor: not-allowed;
}

.billingOption > .billingOptionLabel > .optionContainer {
  margin: 0 0 12px 0;
}

.billingOption > .billingOptionLabel > .optionContainer {
  width: 100%;
  position: relative;
  font-family: 'Nunito Sans';
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  height: 60px;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  align-items: center;
  color: #777;
  font-weight: 800;
}

.billingOption > .billingOptionLabel > .optionContainer > div:nth-child(2) {
  display: inline-block;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  vertical-align: middle;
}

.optionContainerDisabled {
  cursor: not-allowed !important;
}

.billingOption > .billingOptionLabel > .optionContainer:hover {
  cursor: pointer;
}

.billingOption > .billingOptionLabel > .optionContainer > .optionContainerDot {
  margin: 0 30px;
}

.billingOption
  > .billingOptionLabel
  > .optionContainer
  > .optionContainerDot
  > .billingOptionSelect {
  border: 2px solid #d0d0d0;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  vertical-align: middle;
  width: 20px;
}

.billingOptionDotDisabled {
  background-color: lightgray;
}

.billingOption
  > .billingOptionLabel
  > .optionContainer
  > .optionContainerDot
  > .billingOptionSelected {
  border: none;
  box-shadow: 0 0 0 6px #fb6a66;
  height: 12px;
  margin: 6px;
  width: 12px;
}

.venmoLogo > img {
  fill: #008cff;
}

.payPalLogo,
.applePayLogo,
.venmoLogo {
  max-width: 75px;
}

.payPalLogo > img,
.applePayLogo > img,
.venmoLogo > img {
  width: 100%;
}

@media screen and (min-width: 576px) {
  .ptContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 6px;
  }

  .billingOption {
    width: auto;
  }

  .billingOption > .billingOptionLabel > .optionContainer {
    width: auto;
    margin: 0;
  }
}
