.formContainer {
  width: 100%;
}

.formContainer input {
  font-size: 14px;
  padding: 14px 12px;
}
.formContainer select {
  width: 100%;
}
.formContainer label {
  font-size: 12px;
}

.formContainer .buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.formContainer .creditCard {
  margin-top: 24px;
}

.formContainer .creditCard div {
  margin-top: 0;
}

.formContainer .cancelButton {
  margin-right: 12px;
  width: 125px;
}
.formContainer .submitButton {
  width: 204px;
}

@media screen and (min-width: 992px) {
  .formContainer .row {
    display: flex;
    gap: 16px;
    max-width: 654px;
  }

  .formContainer .row div {
    width: unset;
  }

  .formContainer input {
    width: 319px;
  }

  .formContainer .creditCard div {
    margin-top: 6px;
  }

  .formContainer .submitButton,
  .formContainer .cancelButton {
    width: 201px;
  }
  .formContainer .cancelButton {
    margin-right: 24px;
  }
}
