:global(.cart-button) {
  background: none;
  border: none;
  cursor: pointer;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
  outline: none;
}

:global(.cart-button:disabled) {
  color: currentColor;
  cursor: auto;
}

:global(.__react_component_tooltip) {
  pointer-events: auto !important;
}
