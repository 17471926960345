.couponInputContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  transition: max-height 0.6s;
}

.couponInputContainer > .couponInputLabel {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 15px;
}

.discounAndLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family:
    Nunito Sans,
    sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  margin: 0;
}

.discounAndLabel p {
  margin: 0;
  color: #fb6a66;
}

.couponInputContainer .defaultInputWrapper {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.couponInputContainer .defaultInputWrapper button {
  color: #fff;
  border-radius: 5px;
  background: #fb6a66;
  width: 86px;
}

.couponInputContainer .defaultInputWrapper button:hover {
  background: #d65954;
}

.couponInputContainer .secondaryInputWrapper {
  display: flex;
  margin-top: 8px;
}

.couponInputContainer .secondaryInputWrapper svg {
  fill: #fff;
  width: 13px;
  height: 14px;
}

.couponInputContainer .secondaryInputWrapper button {
  width: 40px;
  border-radius: 0 4px 4px 0;
  padding: 14px;
}

.couponInputContainer .secondaryInputWrapper input {
  border-radius: 4px 0 0 4px;
  border: solid 1px #eee;
  padding: 12px 10px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 1px;
  width: 100%;
}

.secondaryInputWrapper::placeholder {
  opacity: 0.4;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
}

.defaultInputWrapper {
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 18px;
  padding: 13px 12px;
  box-sizing: border-box;
  height: 48px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
}

.defaultInputWrapper:disabled {
  background-color: #fff;
}

.defaultInputWrapper::placeholder {
  opacity: 0.4;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
}

.couponInputInvalid {
  border: 2px solid red !important;
  background-color: #ffe5e5;
}

.successMessage {
  margin: 8px 0 0;
  font-size: 12px;
}

@media (min-width: 992px) {
  .couponInputContainer > .couponInputLabel > .defaultInputWrapper > .couponInput {
    font-size: 14px;
  }

  .couponInputContainer > .couponInputLabel > .secondaryInputWrapper > .couponInput {
    font-size: 14px;
  }

  .couponInputContainer > .couponInputLabel > .defaultInputWrapper > .couponInput::placeholder {
    font-size: 14px;
  }
}
