@use '../../sass/colors';
@use '../../sass/fonts';
@use '../../sass/mediaQueries';

.planCardContainer {
  display: flex;
  flex-direction: column;
  border: 2px solid #d0d0d0;
  width: 351px;
  height: fit-content;
  background-color: colors.$white;
  border-radius: 3px;

  .imageContainer {
    display: flex;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    img {
      width: 100%;
      border-top-left-radius: 1px;
      border-top-right-radius: 1px;
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 24px;

    .titleContainer {
      display: flex;
      flex-direction: column;

      h3 {
        font-family: fonts.$playfair;
        font-size: 30px;
        line-height: 40px;
        font-weight: 400;
        margin: 0;
        margin-bottom: 16px;
      }

      .priceContainer {
        display: flex;
        flex-direction: column;
        h3 {
          margin: 0;
          line-height: 40px;
        }
        span {
          font-family: fonts.$nunito;
          font-size: 12px;
          line-height: 24px;
          font-weight: 600;
          color: #979797;
        }
      }
    }

    .perksContainer {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      padding: 0;
      margin: 0;
      gap: 9px;
      margin: 16px 0;

      li {
        display: flex;
        gap: 9px;
        font-size: 14px;
        font-family: fonts.$nunito;

        i {
          color: colors.$primary;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          text-align: center;
        }

        * {
          margin: 0;
        }
      }
    }

    .ctaContainer {
      display: flex;
      button {
        width: 100%;
        border-radius: 3px;
      }
    }
  }
}
