.prospectNavBarContainer {
  display: flex;
  padding: 15px 0px;
  max-width: 1160px;
  margin: 0 auto;
}

.prospectNavBarContainerShop {
  display: flex;
  padding: 15px 0px;
  max-width: 1240px;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .prospectNavBarContainer {
    display: flex;
    padding: 15px 20px;
    max-width: 1200px;
  }

  .prospectNavBarContainerShop {
    display: flex;
    padding: 15px 20px;
    padding-left: 18px;
  }
}

@media (min-width: 1200px) {
  .prospectNavBarContainerShop {
    display: flex;
    padding: 15px 20px;
  }
}
