.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 96px;
  margin-top: 12px;
}

.lineText {
  line-height: 15px;
}
