.recurly-element,
.recurly-hosted-field {
  background-color: #fff;
  border: 1px solid #d7d7d9;
  border-radius: 0;
  box-sizing: border-box;
  padding: 0 11px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  border-radius: 3px;
}

.recurly-hosted-field {
  margin-top: 10px;
  height: 2em;
}

.recurly-element {
  margin-top: 8px;
  width: 100%;
  height: 48px;
}

.recurly-element-focus,
.recurly-hosted-field-focus {
  border: 1px solid #8c8c91;
}

form label.ffInvalid .recurly-element,
.recurly-element-invalid,
.recurly-hosted-field-invalid {
  border: solid 1px #cc484a;
  background-color: rgba(255, 91, 93, 0.05);
}

.recurly-element-month {
  border-radius: 0;
}

.recurly-element-year {
  border-radius: 0;
  border-left: none;
}

form label.ffInvalid .recurly-element-month {
  border-right: none;
}

@media (min-width: 992px) {
  .recurly-element,
  .recurly-hosted-field {
    font-size: 14px;
  }
}
