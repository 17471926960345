.label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: black;
  font-family: 'Nunito Sans';
  padding-top: 24px;
  position: relative;
}

.checkbox {
  opacity: 0;
  position: absolute;
  z-index: 1;
  margin: 0;
  width: 30px;
  height: 100%;
  cursor: pointer;
}

.customCheckbox {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #fff;
  border: solid 1px #d0d0d0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  position: relative;
}

.checkbox:checked + .customCheckbox::after {
  content: '\f00c';
  font-family: 'Font Awesome 5 Pro';
  color: white;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox:checked + .customCheckbox {
  background-color: #ff5b5d;
  border-color: transparent;
}
