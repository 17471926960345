.container {
  padding: 8px 16px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 90;
}

.container .appContainer {
  display: flex;
  align-items: center;
}

.container .appContainer .dbClose {
  background-color: transparent;
  border: none;
  padding: 0;
}

.container .appContainer .logoImage {
  padding: 1px;
  width: 64px !important;
  height: 64px !important;
}

.container .appContainer .dbClose .dbCloseIcon {
  margin-right: 10px;
  color: #bbbbc0;
}

.container .appContainer .details {
  margin-left: 9px;
}

.container .appContainer .details .title {
  font-size: 14px;
  color: #090b0a;
  font-family: 'Nunito Sans', sans-serif;
}

.container .appContainer .details .description {
  font-size: 11px;
  color: #848489;
  font-family: 'Nunito Sans', sans-serif;
}

.container .openButton {
  background-color: #000000;
  color: #ffffff;
  border-radius: 40px;
  border: none;
  padding: 6px 13px;
  text-decoration: none;
  font-family: 'Nunito Sans', sans-serif;
}

.container .openButton:active {
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease;
}
