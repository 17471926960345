@value shared: "../../../shared/shared.module.css";
@value sm from shared;

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  z-index: 2;
}

.overlay {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  position: fixed;
  left: 0;
  top: 0;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  z-index: 5000;
  border: solid 1px #ffdcd1;
  border-radius: 6px;
  text-align: center;
  padding: 36px 24px;
  width: 100%;
}

.container i {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 24px;
}

.container h2 {
  font-family: 'Playfair Display', sans-serif;
  margin: 0;
  font-size: 36px;
  line-height: 36px;
}

.container p {
  font-size: 16px;
  line-height: 24px;
  margin: 24px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* height of buttons + 12px */
  height: calc(48px + 48px + 12px);
}

@media (min-width: sm) {
  .container {
    width: 550px;
    padding: 42px;
  }
  .buttonContainer {
    flex-direction: row;
    height: auto;
    /* width of buttons + 12px */
    width: calc(228px + 228px + 12px);
  }
}
