@use '../../../sass/colors';
@use '../../../sass/fonts';
@use '../../../sass/mediaQueries';

.item {
  display: flex;
  flex-flow: column nowrap;
  font-family: fonts.$nunito;
  text-align: left;
  background-color: colors.$white;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  .clickableArea {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    all: unset;
    background: none;
    border: none;
    color: inherit;
    padding: 0;
    margin: 0;
    outline: none;
  }

  .content {
    padding: 21px 13px 40px 15px;
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
    height: auto;
    box-sizing: border-box;
  }

  .thumbnailContainer {
    width: 100%;
    height: 174px;
    aspect-ratio: 3 / 2;
    background-color: #cecece;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
  .verifiedTag {
    color: #68ada1;
    position: absolute;
    bottom: 14px;
  }
  .title {
    margin: 0 0 4px 0;
    font-weight: fonts.$fontWeightBold;
  }

  .reviewText {
    align-self: flex-end;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
}
