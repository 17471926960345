.boxContainer {
  height: 90.5px;
  display: flex;
  border-bottom: solid 1px #e8e8e8;
  padding: 12px 18px;
  box-sizing: border-box;
}

.imageContainer {
  display: flex;
  align-items: center;
  width: 60px;
}

.image {
  width: 100%;
}

.nameAndLinkContainer {
  margin-left: 12px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: center;
}

.boxName {
  margin-bottom: 4px;
}

.prepaidContainer {
  display: flex;
  align-items: center;
  text-align: right;
}

.prepaid {
  color: #00000099;
  width: 55px;
}

.disclaimer {
  padding: 8px 18px;
  font-size: 12px;
  background-color: #fafafa;
  color: #646464;
  text-align: center;
}
