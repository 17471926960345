@value shared: "../../../shared/shared.module.css";
@value sm from shared;

.container {
  height: 78px;
  padding: 12px 18px;
  display: flex;
  background: #fdf1eb;
  align-items: center;
  justify-content: space-between;
}

.content {
  max-width: 85%;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.box {
  width: 22px;
  height: 18px;
  margin-right: 16px;
}

.description u {
  cursor: pointer;
}

.title {
  font-weight: 700;
}

.note {
  font-weight: 700;
  color: #fb6a66;
}

.wrapperClose {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
}

.close path {
  stroke: #fdf1eb;
}

.add {
  cursor: pointer;
  width: 44px;
  height: 24px;
  border-radius: 3px;
  font-size: 10px;
  font-weight: 800;
  color: #fb6a66;
  background-color: #fff;
  border: solid 1px #fb6a66;
  letter-spacing: 1px;
  margin-right: 12px;
}
