@use '../../../../../../../../../../shared/ui/design-tokens/src/lib/sass/colors' as colors;
@use '../../../../../../../../../../shared/ui/design-tokens/src/lib/sass/border' as border;
@use '../../../../../../../../../../shared/ui/design-tokens/src/lib/sass/text' as text;
@use '../../../../../../../../../../shared/ui/ui-styles/src/lib/mediaQueries' as media;

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.doubleInputRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include media.is-above(media.$medium) {
    flex-direction: row;

    > * {
      flex: 1; /* Make all direct children take equal space */
    }
  }
}

.content .doubleInputRow .zipCodeInput {
  margin-top: 10.5px;
}

.checkboxLabel {
  padding-top: 16px;
}

.checkboxDisclaimer {
  font-size: 12px;
  color: colors.$colors-black-primary;
  font-family: text.$text-font-family-nunito;
  line-height: 14px;

  a {
    cursor: pointer;
  }
}

.phoneInput {
  width: 100%;
  @include media.is-above(media.$medium) {
    max-width: calc(50% - 4px);
  }
}
