.ufContainer {
  max-width: 644px;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.ufContainer > .ufTitle {
  margin-top: 36px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
}

.ufContainer > .ufCard {
  height: 24px;
  margin-top: 24px;
  margin-bottom: -1px;
  display: flex;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.ufContainer > .ufCard > span.lastFour {
  margin-left: 12px;
  margin-right: 4px;
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .ufContainer > .ufTitle {
    text-align: center;
  }

  .ufContainer > .ufCard {
    margin-top: 28px;
    margin-bottom: 6px;
    justify-content: center;
  }
}
