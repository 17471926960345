@value shared: "../../../shared/shared.module.css";
@value sm from shared;

.cart {
  position: fixed;
  top: 0;
  left: 0;
  float: right;
  height: 100%;
  width: 100vw;
  background-color: white;
  z-index: 999999;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-direction: column;
  letter-spacing: normal;
}

.cartContentWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.scrollContain {
  overflow-y: hidden;
  position: fixed;
}

@media (min-width: sm) {
  .cart {
    height: auto;
    min-height: 550px;
    max-height: calc(100vh - 140px);
    max-width: 375px;
    margin-top: 14px;
    position: relative;
    border-radius: 6px;
  }

  .cart::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    top: -10px;
    right: 9px;
    border-bottom-color: #f9f9f9;
    border-bottom-style: solid;
    border-bottom-width: 10px;
    z-index: 4000;
  }

  .cart::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    top: -10px;
    right: 9px;
    border-bottom-color: #f9f9f9;
    border-bottom-style: solid;
    border-bottom-width: 10px;
    z-index: 4000;
  }
}
