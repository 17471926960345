.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 16px;
  text-align: center;
}

.container i {
  font-size: 36px;
  color: #fb6a66;
  margin-bottom: 18px;
}

.container p.title {
  font-weight: 700;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 6px;
}

.container p.description {
  opacity: 0.6;
  white-space: nowrap;
  font-size: 14px;
  line-height: 18px;
}
