@use '../../../../../../../../ui/fff-ui-shared/src/sass/fonts' as fonts;

.mobileMenu {
  background-color: white;
  transform: scaleY(0);
  z-index: 100;
  height: 0;
  position: absolute;
  width: 100%;
  transition: height 0.2s;
  transition: transform 0.2s;

  ul {
    background-color: white;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto;
    padding: 0;

    li {
      list-style: none;
      text-transform: uppercase;
      padding: 14px 20px;
      border-bottom: 1px solid #e8e8e8;

      a {
        border: 0;
        background-color: transparent;
        color: var(--black);
        font-family: fonts.$nunito;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 19px;
        margin: 0;
        padding: 0;
        text-align: left;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.2s;
      }
    }
  }
}

.showMenu {
  transform: scaleY(1);
}
