@use '../../../sass/colors';
@use '../../../sass/fonts';
@use '../../../sass/mediaQueries';

/* AnnualPlan */
.planSubTitle {
  color: colors.$primary;
  font-family: fonts.$nunito, sans-serif;
  font-size: 9px;
  font-weight: fonts.$fontWeightBold;
  text-wrap: nowrap;
  padding: 7px 0;
  margin: 0;
}

.planDisclaimer {
  color: colors.$grayDark;
  font-family: fonts.$nunito, sans-serif;
  font-size: 9px;
  margin: 12px 0;
  text-wrap: nowrap;
}

@media (min-width: mediaQueries.$medium) {
  .planSubTitle {
    font-size: 14px;
    padding: 7px 0;
    text-wrap: wrap;
    font-weight: fonts.$fontWeightBold;
  }

  .planDisclaimer {
    font-size: 12px;
    margin: 12px 0;
  }
}
