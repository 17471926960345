.promoCodeContainer {
  color: #000;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 12px 0;
}

.promoCodeContainer .promoCodeHeader {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  cursor: pointer;
}

.promoCodeContainer .promoCodeHeader .visibilityToggle {
  font-size: 14px;
  font-weight: 900;
  line-height: 18px;
  width: 13px;
}

.promoCodeContainer .promoCodeContent {
  display: flex;
  margin-top: 12px;
  width: 100%;
}

.promoCodeContainer .promoCodeContent label {
  display: none;
}

.promoCodeContainer .promoCodeContent .promoCodeInputContainer {
  flex-grow: 1;
  margin-right: 8px;
  width: auto;
}

.promoCodeContainer .promoCodeContent .buttonContainer {
  width: 86px;
  min-width: 86px;
}

.promoCodeContainer .promoCodeContent .promoCodeInputContainer .input,
.promoCodeContainer .promoCodeContent button {
  border-radius: 3px;
}

.promoCodeContainer .errorMessage {
  color: #ff5b5d;
  font-weight: normal;
  margin-top: 5px;
  display: block;
  font-size: 12px;
  line-height: 16px;
}

@media (min-width: 992px) {
  .promoCodeContainer .promoCodeContent .promoCodeInputContainer div {
    width: auto;
  }
}
