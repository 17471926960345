@value shared: "../shared.module.css";
@value xl from shared;
@value md from shared;

.container {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  width: 100%;
  position: relative;
}

.container label {
  font-size: 12px;
  line-height: 1.25;
  margin-bottom: 8px;
}

.input {
  height: 48px;
  font-family: 'Nunito Sans', sans-serif;
  cursor: text;
  display: flex;
  padding: 12px;
  font-size: 16px;
  border: solid 1px #ccd0d2;
  -webkit-appearance: none;
  background-color: transparent;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.error {
  border: solid 1px #cc484a;
  background-color: rgba(255, 91, 93, 0.05);
}

.input:disabled {
  border: solid 1px #eeeff0;
  background-color: #eeeff0;
}

.input:disabled:hover {
  cursor: default;
}

.input::placeholder {
  color: #979797;
}

.input:disabled::placeholder {
  color: #666666;
}

.priceInput {
  display: flex;
  align-items: center;
}

.priceInput i {
  font-size: 16px;
  margin-right: 12px;
}

.textArea {
  height: 90px;
  resize: vertical;
}

.dateInput {
  width: 200px;
}

.errorMessage {
  line-height: 15px;
  font-size: 12px;
  color: #fb6a66;
  margin: 0;
  margin-top: 6px;
}

@media screen and (min-width: xl) {
  .container {
    width: 282px;
  }

  .input {
    font-size: 14px;
  }
}
