.form {
  font-family: 'Nunito Sans', sans-serif;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.form .billingInfoHeader {
  margin-top: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d0d0d0;
}

.form .billingInfoHeader h3 {
  color: #000;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.form .paymentMethod > div {
  font-size: 16px;
  line-height: 24px;
  padding: 0 24px;
  margin-top: 16px;
}

.form .formRow {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 24px;
}

.form .recurlyFormRow {
  gap: 0;
}

.form .formRow label {
  font-size: 12px;
  line-height: normal;
}

.form .selectContainer {
  width: 100%;
}

.form .inputContainer {
  width: 100%;
}

.form .input,
.form .select {
  border-radius: 3px;
  width: 100%;
  margin: 0;
  font-size: 16px;
  padding: 14px 12px;
}

.form .zipCodeInput {
  width: 100%;
  margin: 0;
}

.form .checkboxFormRow {
  display: flex;
  gap: 6px;
  margin-top: 24px;
}

.form .checkboxFormRow input[type='checkbox'] {
  accent-color: #ff5b5d;
  margin-right: 8px;
  scale: 1.5;
}

.form .checkboxFormRow .checkboxLabel {
  font-size: 16px;
  line-height: normal;
  line-height: 24px;
}

.form .doubleInputContainer {
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  flex-direction: column;
}

.form .doubleInputContainer > div {
  width: 100%;
}

.form .error {
  color: #ff5b5d;
  font-size: 12px;
  line-height: normal;
  margin-top: 4px;
}

.form .footer {
  display: flex;
  margin-top: auto;
  gap: 12px;
  margin-top: auto;
  padding-top: 24px;
}

.form .footer .buttonContent {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.form .footer .updateButton {
  min-width: 204px;
}

.form .footer button {
  border-radius: 3px;
}

.form .footer button:disabled {
  cursor: not-allowed;
}

@media (min-width: 576px) {
  .form .doubleInputContainer {
    flex-direction: row;
  }

  .form .doubleInputContainer > div {
    width: 50%;
  }
}
