.exchangeRate {
  border: 2px solid #fce5e3;
  padding: 10px;
  margin-bottom: 15px;
}

.exchangeRatePrice {
  font-size: 11.5px;
  font-weight: 700;
  color: #4a4a4a;
  font-weight: 700;
}

.exchangeRatePrice > span {
  display: block;
}

.exchangeRatePriceTitle {
  float: left;
}

.exchangeRatePriceValue {
  float: right;
  text-align: right;
}

.exchangeRateDisclaimer {
  clear: both;
  font-size: 9px;
  font-weight: 400;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #878787;
  margin: 25px 0 0;
}
