@use '../../sass' as sass;

.phoneInputContainer {
  position: relative;
  width: 100%;
  margin-top: 4px;
}

.phoneInputLabel {
  display: block;
  font-family: sass.$nunito;
  font-size: 12px;
  line-height: 15px;
  position: relative;
}

.phoneInput {
  height: 48px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  box-sizing: border-box;
  display: block;
  padding: 12px;
  margin-top: 8px;
  font-family: sass.$nunito;
  font-size: 16px;

  &:disabled {
    background: #eeeff0;
  }

  @media (min-width: sass.$medium) {
    font-size: 14px;
  }
}

.phoneInputError {
  color: sass.$primary;
  font-family: sass.$nunito;
  font-size: 12px;
  line-height: 1.25;
  margin: 5px 0 6px;
  min-height: 15px;
}

/* Validation styling classes */
.phoneInputValid {
  border-color: sass.$statusGreen;
  background-color: #f8fff8;
}

.phoneInputInvalid {
  border-color: sass.$primary;
  background-color: #fff8f8;
}
