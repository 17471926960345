.ciContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  text-align: left;
  transition: max-height 0.6s;
}

.ciContainer > .ciLabel {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 15px;
}

.ciContainer > .ciLabel > .ciInputWrapper {
  margin-top: 8px;
  display: flex;
}

.ciContainer > .ciLabel > .ciInputWrapper > .ciInput {
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 18px;
  padding: 13px 12px;
  box-sizing: border-box;
  height: 48px;
  border: 1px solid #d0d0d0;
  border-right: 0;
}

.ciContainer > .ciLabel > .ciInputWrapper > .ciInput.ciInvalid {
  border: solid 1px #cc484a;
  background-color: rgba(255, 91, 93, 0.05);
}

.ciContainer > .ciLabel > .ciInputWrapper > .ciInput::placeholder {
  opacity: 0.4;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
}

@media (min-width: 992px) {
  .ciContainer > .ciLabel > .ciInputWrapper > .ciInput {
    font-size: 14px;
  }

  .ciContainer > .ciLabel > .ciInputWrapper > .ciInput::placeholder {
    font-size: 14px;
  }
}
