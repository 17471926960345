.orderRow {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 10px 0;
}

.orderRow p {
  margin: 0;
  word-break: break-word;
}

.orderText {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 1.42857143;
  margin: 0;
  display: flex;
  align-items: center;
}

.orderTextRed {
  color: #fb6a66;
}

.fullWidth {
  width: 100%;
}

.prices {
  display: flex;
  align-items: center;
}

.crossedPrice {
  color: #9b9b9b;
  text-decoration: line-through;
  margin-right: 3px;
}

.savingsLineItemRow {
  justify-content: center;
  margin: 6px 0;
  padding-bottom: 0;
}
.savingsLineItemRow p {
  width: 100%;
}

.productImage {
  max-width: 36px;
  width: 36px;
  display: flex;
  align-items: center;
}

.productImage img {
  margin-right: 13px;
  height: 27px;
  width: 27px;
}

.quantity {
  font-size: 16px;
  line-height: 24px;
  padding: 0 6px 0 8px;
  width: 37px;
}

.price {
  display: inline-block;
  margin-left: 10px;
  min-width: 54px;
  text-align: right;
}

.lineBelow {
  border-bottom: 1px solid #e8e8e8;
  padding: 12px 0;
}

.checkoutDetailsRow {
  border: none;
  font-size: 16px;
  line-height: 18px;
  margin: 6px 0;
  padding: 0;
}

.finePrint {
  font-size: 12px;
  color: #666666;
  margin: 0;
}

.note {
  font-size: 16px;
  margin: 6px 0;
}

.subscriptionDisclaimer a {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: normal;
  letter-spacing: normal;
  color: #3858cb;
  text-decoration: underline;
}

.subscriptionDisclaimer a:visited {
  text-decoration: underline;
  color: #3858cb;
}

.subscriptionDisclaimer a:hover {
  text-decoration: underline;
  color: #8ea7ff;
}

.subscriptionDisclaimer a:active {
  text-decoration: none;
  color: #4a72ff;
}

.subscriptionDisclaimer a:disabled {
  text-decoration: none;
  color: #faccca;
}

.cadence {
  color: #363636;
  display: block;
}

@media screen and (min-width: 768px) {
  .orderText {
    font-size: 14px;
  }

  .quantity {
    font-size: 14px;
    width: 34px;
  }

  .checkoutDetailsRow {
    font-size: 14px;
  }

  .note {
    font-size: 14px;
  }
}
