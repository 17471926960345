.checkboxWrapper * div {
  display: flex;
  justify-content: center;
  padding-left: 0px !important;
  display: none;
}

.checkboxWrapper label {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  color: #111;
  line-height: 0;
  cursor: auto !important;
}
.checkboxWrapper div:last-child {
  padding: 0 10px;
}
.checkboxWrapper input {
  visibility: hidden;
}

.checkboxWrapper a {
  color: #3858cb;
}

.termsAndConditions {
  display: flex;
  justify-content: center;
}

.termsAndConditions > p {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  padding: 0 10px;
  color: #111;
}

.termsAndConditions a {
  color: #3858cb;
}

.termsAndConditionsOnVariation > p {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  text-align: center;
  margin-top: 0;
  color: #000;
  margin-bottom: 18px;
}

.termsAndConditionsOnVariation a {
  color: #4a72ff;
}
