@use '../../sass/mediaQueries' as *;

.radioOptionWrapper {
  padding: 6px 0;
  display: flex;
  border: none;
  align-items: center;
  background: none;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  margin: 0;
}

.radioOptionWrapper:focus {
  outline: none;
}

.radioOptionWrapper:nth-child(1) {
  margin: 0;
}

.radioOptionWrapper > .radioInput {
  width: 20px;
  height: 20px;
  border: solid 2px #d0d0d0;
  background-color: #ffffff;
  margin: 0 15px 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: pointer;
  pointer-events: none;
}

.radioOptionWrapper > .radioInput:checked {
  border: solid 5px #fb6a66;
}

.radioOptionWrapper > .radioInput,
.radioOptionWrapper > .radioInput:checked {
  -webkit-transition: border 0.2s ease-in-out;
  -moz-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

.radioOptionWrapper > .label {
  font-size: 14px;
}

@media screen and (min-width: $medium) {
  .radioOptionWrapper > .radioInput {
    width: 24px;
    height: 24px;
  }

  .radioOptionWrapper > .radioInput:checked {
    border: solid 6px #fb6a66;
  }
}
