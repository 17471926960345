.navigation {
  width: 375px;
  margin: 0 auto;
  padding: 17px 0 0;
  text-align: center;
  display: flex;
  justify-content: center;
}

.navigation.large {
  width: 460px;
}

.node {
  font-family: 'Nunito Sans', sans-serif;
  display: inline-block;
  width: 56px;
  margin: 0 23px 0 0;
  position: relative;
}

.large .node {
  margin: 0 45px 0 0;
}

.node::before {
  content: '';
  width: 33px;
  border-bottom: 2px solid #d0d0d0;
  position: absolute;
  top: 25px;
  left: -28px;
}

.large .node::before {
  left: -49px;
  width: 53px;
}

.node.selected::before {
  border-bottom: 2px solid #c96fb6;
}

.node:first-child::before {
  border: none;
}

.node:last-child {
  margin-right: 0;
}
.node a {
  text-decoration: none;
  display: inline-block;
  color: #fff;
  margin: 0;
}
.circle {
  width: 48px;
  height: 48px;
  border-radius: 36px;
  background: #d0d0d0;
  border: 6px solid rgba(208, 208, 208, 0.2);
  border: 1px solid #f6f6f6;
  box-shadow: inset 0 0 0 5px #fff;
  padding: 6px;
  padding: 11px;
  background-clip: padding-box;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  margin: 0 auto;
  box-sizing: border-box;
}
.selected .circle {
  background: #c96fb6;
  border: 6px solid rgba(201, 111, 182, 0.2);
  background-clip: padding-box;
  padding: 6px;
  box-shadow: none;
}
.title {
  font-size: 12px;
  line-height: 15px;
  color: #000;
  text-align: center;
  margin: 7px auto 0;
}
