.remainingCredit {
  background-color: #d2ece8;
  height: 33px;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.remainingCredit span {
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
}

.itemsContainer {
  overflow-y: auto;
  flex: 1;
}
