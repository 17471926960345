.review-button {
  display: flex;
  align-items: center;
  width: 174px;
  height: 48px;
  border: 1px solid #fb6a66;
  border-radius: 6px;
  margin: 17px auto 5px auto;
  padding: 10px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left;
  color: #fb6a66;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
}
.review-button:hover {
  border: 1px solid #faccca;
}
.review-button:active {
  border: 1px solid #c75552;
}
.review-button i {
  color: #fb6a66;
  font-weight: 300;
  margin-right: 14px;
}

.review-button-desktop {
  width: 180px;
  height: 36px;
  border: 1px solid #fb6a66;
  border-radius: 6px;
  margin: 11px 0 0 0;
  padding: 10px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: normal;
  text-align: left;
  color: #fb6a66;
  background-color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
}

.review-button-desktop:hover {
  border: 1px solid #faccca;
}
.review-button-desktop:active {
  border: 1px solid #c75552;
}
.review-button-desktop i {
  color: #fb6a66;
  font-weight: 300;
  margin-right: 14px;
}

@media screen and (min-width: 1024px) {
  .review-button {
    width: 180px;
    height: 36px;
  }
}
