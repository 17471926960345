.container {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  width: 282px;
  margin-right: 24px;
}

.container::before {
  content: '\f078';
  font-family: 'Font Awesome 5 Pro';
  color: #9b9b9b;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 8px;
  top: 36px;
}

.label {
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
  line-height: 1.25;
  margin-bottom: 8px;
}

.select {
  height: 48px;
  font-family: 'Nunito Sans', sans-serif;
  cursor: pointer;
  display: flex;
  padding: 12px 32px 12px 12px;
  font-size: 16px;
  border: solid 1px #ccd0d2;
  border-radius: 6px;
  -webkit-appearance: none;
  background-color: transparent;
}

.select:disabled {
  border: solid 1px #eeeff0;
  background-color: #eeeff0;
  color: #666666;
}

.select:disabled:hover {
  cursor: default;
}

.select:-webkit-autofill,
.select:-webkit-autofill:hover,
.select:-webkit-autofill:focus,
.select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.error {
  border: solid 1px #cc484a;
  background-color: rgba(255, 91, 93, 0.05);
}

.errorMessage {
  line-height: 15px;
  font-size: 12px;
  color: #fb6a66;
  margin: 0;
  margin-top: 6px;
}

@media screen and (min-width: xl) {
  .select {
    font-size: 14px;
  }
}
