.ffContainer {
  font-family: 'Nunito Sans', sans-serif;
  margin-top: 31px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 15px;
}

.ffContainerGtb {
  font-family: 'Nunito Sans', sans-serif;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 15px;
}

.ffContainer > .ffInput.ffInvalid,
.ffContainerGtb > .ffInput.ffInvalid {
  border: solid 1px #cc484a;
  background-color: rgba(255, 91, 93, 0.05);
}

.ffContainer > .ffInput,
.ffContainerGtb > .ffInput {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  padding: 12px;
  margin-top: 8px;
  height: 48px;
  box-sizing: border-box;
  border: solid 1px #d0d0d0;
  border-radius: 3px;
  outline: none;
}

.ffContainer > .ffInput:focus,
.ffContainerGtb > .ffInput:focus {
  border: 1px solid #8c8c91;
}

.ffContainer > .ffInput::placeholder,
.ffContainerGtb > .ffInput::placeholder {
  font-family: 'Nunito Sans', sans-serif;
  color: rgba(0, 0, 0, 0.4);
  font-size: 16px;
  line-height: 18px;
}

.ffContainer > .ffInput::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ffInput[type='number'] {
  -moz-appearance: textfield;
}

@media (min-width: 992px) {
  .ffContainer > .ffInput::placeholder,
  .ffContainerGtb > .ffInput::placeholder {
    font-size: 14px;
  }

  .ffContainer > .ffInput,
  .ffContainerGtb > .ffInput {
    font-size: 14px;
  }
}
