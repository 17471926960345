.csrfContainer {
  font-family: 'Nunito Sans', sans-serif;
  margin-top: 31px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 15px;
}

.csrfChevron::after {
  font-family: 'Font Awesome 5 Pro', sans-serif;
  position: absolute;
  content: '\f0d7';
  font-size: 14px;
  color: #555555;
  font-weight: 900;
  transform: translateY(27px) translateX(-21px);
  pointer-events: none;
}

.csrfSelect {
  font-family: 'Nunito Sans', sans-serif;
  border-radius: 3px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  font-weight: 400;
  opacity: 0.6;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  height: 48px;
  padding: 15px 12px;
  margin-top: 8px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}

.csrfSelect:focus {
  border: 1px solid #8c8c91;
}

.csrfSelect.csrfInvalid {
  border: solid 1px #cc484a;
  background-color: rgba(255, 91, 93, 0.05);
}
