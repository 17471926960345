@use '../../sass/colors';
.rows {
  padding: 12px 0;

  h3 {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }

  .row {
    display: flex;
    justify-content: space-between;

    input {
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      margin: 0;
      margin-right: 16px;
      font: inherit;
      width: 20px;
      height: 20px;
      border: 2px solid colors.$grayLight;
      border-radius: 50%;
      display: grid;
      place-content: center;

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #fff;
      }

      &:checked {
        background-color: colors.$primary;
        border: 2px solid colors.$primary;

        &::before {
          transform: scale(1);
        }
      }
    }

    .addressMargin {
      margin-left: 18px;
    }

    .address {
      display: flex;
      width: 100%;

      div {
        max-width: 88%;
        font-size: 16px;

        p {
          margin: 0;
        }

        span {
          color: #ff5b5d;
          cursor: pointer;
        }
      }
      .removeAddress {
        margin-left: 8px;
        padding-left: 8px;
        display: inline-block;
        border-left: solid 1px colors.$grayLight;
      }
    }
  }

  .row:not(:first-child) {
    margin-top: 16px;
  }

  .rowAccount + .row {
    margin-top: 4px;
  }
}
