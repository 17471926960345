@use '../../sass/colors';
@use '../../sass/fonts';
@use '../../sass/mediaQueries';

.wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  text-align: center;
  padding-top: 63px;
  padding-bottom: 60px;

  .title {
    font-family: fonts.$playfair;
    font-weight: 400;
    font-size: 36px;
    margin: 0;
    text-transform: capitalize;
    padding: 0 0 21px 0;
  }

  .subtitle {
    font-family: fonts.$nunito;
    padding-top: 12px;
    font-size: 14px;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .subtitleBold {
    font-weight: 700;
  }

  .button {
    padding: 15px 16px;
    border-radius: 3px;
  }

  .ctaContainer {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: mediaQueries.$small) {
  .wrapper {
    .title {
      padding: 0;
    }
  }
}

@media (min-width: mediaQueries.$extraLarge) {
  .wrapper {
    max-width: 1440px;
    padding-bottom: 63px;
  }
}

.noCarouselStyle {
  justify-content: center;
}
