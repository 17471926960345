.wbContainer {
  display: flex;
  border: 1px solid #f7b343;
  border-left: 6px solid #f7b343;
  border-radius: 6px;
  max-width: 641px;
  width: 100%;
  height: 84px;
  margin: 21px auto 27px auto;
  box-sizing: border-box;
}

.wbContainer > .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 11px auto 12px;
  box-sizing: border-box;
}

.wbContainer > .icon > .faExclamationCircle {
  font-size: 24px;
  color: #f7b343;
}

.wbContainer > .message {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wbContainer > .message > .title {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: normal;
  color: #000;
  margin: 0 0 3px 0;
}

.wbContainer > .message > .subtitle {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: normal;
  color: #000;
  opacity: 0.6;
  margin: 0;
}
