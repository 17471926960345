.ciContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  text-align: left;
  transition: max-height 0.6s;
}

.ciContainer > .ciLabel {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 15px;
}

.ciContainer .ciInputWrapper {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.ciContainer .ciInputWrapper button {
  color: #fff;
  border-radius: 5px;
  background: #fb6a66;
  width: 86px;
}

.ciContainer .ciInputWrapper button:hover {
  background: #d65954;
}

.ciContainer .secondaryInputWrapper {
  display: flex;
  margin-top: 8px;
}

.ciContainer .secondaryInputWrapper svg {
  fill: #fff;
  width: 13px;
  height: 14px;
}

.ciContainer .secondaryInputWrapper button {
  width: 40px;
  border-radius: 0 4px 4px 0;
}

.ciContainer .secondaryInputWrapper input {
  border-radius: 4px 0 0 4px;
}

.ciInput {
  width: 100%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  line-height: 18px;
  padding: 13px 12px;
  box-sizing: border-box;
  height: 48px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
}

.ciInput:disabled {
  background-color: #fff;
}

.ciInput.ciInvalid {
  border: solid 1px #cc484a;
  background-color: #fff;
}

.ciInput::placeholder {
  opacity: 0.4;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
}

@media (min-width: 992px) {
  .ciContainer > .ciLabel > .ciInputWrapper > .ciInput {
    font-size: 14px;
  }

  .ciContainer > .ciLabel > .ciInputWrapper > .ciInput::placeholder {
    font-size: 14px;
  }
}
