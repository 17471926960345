@value shared: "../../../shared/shared.module.css";
@value sm from shared;

.container {
  display: flex;
  flex-direction: column;
  padding: 12px 18px;
  border-bottom: solid 1px #e8e8e8;
}

.additionalChoiceContainer {
  cursor: pointer;
}

.variant {
  display: flex;
  justify-content: space-between;
}

.nakedButton {
  background: none;
  border: none;
  cursor: pointer;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
  color: black;
}

.imageLink {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
}

.imageLink img {
  min-width: 100%;
  height: 100%;
}

.name {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  max-width: 192px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
  font-size: 12px;
}

.price {
  display: flex;
  justify-content: flex-end;
  width: 55px;
  white-space: nowrap;
  line-height: 18px;
  margin-bottom: 1px;
}

.strikethroughPrice {
  text-decoration: line-through;
  color: #9b9b9b;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.warning {
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #ff3b30;
  margin: 12px 0 0 72px;
}

.cadence {
  font-size: 12px;
  line-height: 15px;
  color: #363636;
  margin-top: 4px;
}
