/* Accordion Container */
.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  color: #333;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
}

.accordion-icon {
  transition: transform 0.3s ease;
}

.accordion-icon.open {
  transform: rotate(90deg);
}

.accordion-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  font-size: 14px;
  background-color: #f9f9f9;
  padding: 16px 24px;
  border-top: 1px solid #e5e5e5;
}

.accordion-item {
  display: block;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-decoration: none;
  transition: color 0.2s;
}

.accordion-item:hover {
  text-decoration: underline;
}
