.orderSummary {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 9px;
  padding: 18px;
  margin: 0 0 18px;
  width: 100%;
  max-width: inherit;
}
.orderSummary.noContainer {
  border: none;
  margin: 0;
  padding: 0;
}

.orderSummaryTitleWrapper {
  border-bottom: 2px solid #e8e8e8;
  margin: 0 0 10px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.orderSummaryTitle {
  font-size: 18px;
  line-height: 1.33;
  font-weight: 600;
  margin: 0 0 10px;
}

.orderSection {
  padding: 0;
}

.hr {
  border-top: 1px solid #eee;
}

@media screen and (min-width: 768px) {
  .orderSummary {
    padding: 20px;
  }
}
