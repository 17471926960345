@use '../../sass/colors';
@use '../../sass/fonts';
@use '../../sass/mediaQueries';

.modal {
  width: calc(100vw - 76px);
  background-color: #fff;
  border: 1px solid #ffdcd1;
  border-radius: 6px;
}

.container {
  position: relative;
  padding: 42px 18px;

  button[name='close-button'] {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.content {
  position: relative;
  text-align: center;
}

.wrapper {
  margin: 6px 6px 0 6px;

  p {
    font-size: 14px;
    line-height: 18px;
  }
}

.title {
  font-family: fonts.$playfair;
  font-size: 30px;
  line-height: 36px;
  font-weight: bold;
  margin: 0;

  span {
    display: block;
  }
}

.subtitle {
  margin: 6px 0 0 0;

  span {
    display: block;
  }
}

.highlighted {
  color: colors.$secondaryDark;
  font-weight: bold;
}

.suggestion {
  margin: 12px 0 0 0;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.address {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 115px;
  border: 1px solid colors.$grayLight;
  border-radius: 6px;
  margin-top: 24px;
  padding: 12px 0;

  > p {
    margin: 0;
  }
}

.button {
  max-width: 100%;
  margin-top: 18px;
}

.link {
  background: none;
  border: none;
  color: colors.$primary;
  font-family: fonts.$nunito;
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    color: #ff4646;
  }

  &:disabled {
    pointer-events: none;
  }
}

.closeButton {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

@media screen and (min-width: mediaQueries.$medium) {
  .modal {
    width: 552px;
  }

  .container {
    padding: 42px 82px;
  }

  .wrapper p {
    font-size: 16px;
    line-height: 24px;
  }

  .title span {
    display: inline-block;
  }

  .address {
    margin-bottom: 30px;
  }

  .options {
    margin-bottom: 30px;
  }

  .button {
    width: 250px;
    display: block;
    margin: 0 auto;
  }
}
