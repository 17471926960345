html,
body,
#root {
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

.fff-btn-primary,
.fff-btn-primary-black-outline,
.fff-btn-black-small,
.fff-btn-black-outline-small {
  display: block;
  width: 188px;
  background-color: #000000;
  border: solid 1px #000000;
  cursor: pointer;
  padding: 14px 16px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.13;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fff-btn-black-small:hover {
  border-color: #9b9b9b;
  background-color: #9b9b9b;
}

.fff-btn-black-outline-small {
  background-color: #ffffff;
  color: #000000;
  border: solid 1px #000000;
}

.fff-btn-black-outline-small:hover {
  border: solid 1px #d0d0d0;
}

.fff-btn-black-outline-small:active {
  border: solid 1px #9b9b9b;
}

.fff-btn-black-outline-small.disabled {
  background-color: #d0d0d0;
  border: none;
  color: #d0d0d0;
}

.fff-btn-primary {
  border: none;
  padding: 15px 58px;
  background-color: #ff5b5d;
  line-height: 18px;
  font-size: 16px;
  width: fit-content;
}

.fff-btn-primary-black-outline {
  padding: 14px 58px;
  color: #000000;
  border: solid 1px #000000;
  background-color: #ffffff;
  line-height: 18px;
  font-size: 16px;
  width: fit-content;
}

.fff-btn-primary:hover {
  background-color: #fa9c98;
}

.fff-btn-primary:active {
  background-color: #c75552;
}

.fff-btn-primary-black-outline:hover {
  border: solid 1px #d0d0d0;
}

.fff-btn-primary-black-outline:active {
  border: solid 1px #9b9b9b;
}

.membership-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 990px;
  width: 100%;
}
