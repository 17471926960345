@value shared: "../shared.module.css";
@value lg from shared;
@value md from shared;

.editForm {
  overscroll-behavior: contain;
  padding: 0;
}

.header {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  padding: 18px 0px 0px 0px;
  margin: 0px;
}

.readOnlySection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.readOnlyFields {
  display: flex;
  flex-direction: column;
  width: 300px;
  text-align: left;
}

.readOnlySection button {
  color: #666666;
  font-size: 14px;
  text-align: left;
  border: none;
  background: none;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  padding-top: 24px;
  font-family: 'Nunito Sans';
  padding-left: 0;
}

.readOnlySection {
  button:hover {
    color: black;
  }
}

.phoneNumber {
  padding-top: 12px;
}

.editInnerContainer {
  margin-top: 0px;
}

.inputContainer {
  margin-top: 31px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;

  button {
    border-radius: 3px;
  }

  button:first-child {
    margin-right: 12px;
    width: 127px;
  }

  button:nth-child(2) {
    width: calc(100% - 139px);
  }
}

@media screen and (min-width: lg) {
  .editForm {
    padding: 0px;
  }

  .readOnlySection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .readOnlySection button {
    text-align: right;
    padding: 0 0 25px 0;
  }

  .editInnerContainer {
    display: flex;
    flex-direction: row;
    font-family: 'Nunito Sans';
    flex-wrap: wrap;
  }

  .editInnerContainer div:nth-child(odd) {
    margin-right: 18px;
  }

  .editInnerContainer div {
    flex-basis: 48%;
  }

  .buttonsContainer {
    justify-content: center;

    button:first-child {
      margin-right: 24px;
      width: 201px;
    }

    button:nth-child(2) {
      width: 201px;
    }
  }
}
