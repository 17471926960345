.seasonal-timeline {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 15px;
  max-width: 1200px;
}

.seasonal-timeline__timeline {
  overflow: hidden;
  position: relative;
}

.seasonal-timeline__timeline__buttons {
  display: flex;
  flex-direction: column;
  margin: -7px 12px 0 12px;
}

.seasonal-timeline__buttons {
  padding-top: 4px;
}

.seasonal-timeline__buttons .fff-btn-primary,
.seasonal-timeline__buttons .fff-btn-primary-black-outline {
  width: 100%;
  height: 48px;
  box-sizing: border-box;
}

.seasonal-timeline__buttons .fff-btn-primary {
  margin-bottom: 12px;
}

.seasonal-timeline .divider {
  margin-top: 12px;
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .seasonal-timeline__timeline {
    margin: auto;
  }

  .seasonal-timeline__timeline__buttons {
    flex-direction: column !important;
  }

  .seasonal-timeline__buttons {
    display: flex;
    justify-content: space-around;
  }

  .timeline__node-box:last-child {
    padding-right: 0;
  }

  .timeline__node-box:last-child .timeline__node-box__container {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .seasonal-timeline {
    margin: 12px auto 18px auto;
  }

  .seasonal-timeline__container {
    margin-bottom: 31px;
  }

  .seasonal-timeline__timeline__buttons {
    flex-direction: row;
    margin: 0;
  }

  .seasonal-timeline__container h4 {
    margin-left: 4px;
    margin-bottom: 0;
  }

  .seasonal-timeline__buttons {
    padding-top: 20px;
    margin-left: -2px;
  }

  .seasonal-timeline__buttons .fff-btn-primary,
  .seasonal-timeline__buttons .fff-btn-primary-black-outline {
    width: 282px;
    height: 48px;
    box-sizing: border-box;
    padding-right: 0;
    padding-left: 0;
  }

  .seasonal-timeline__buttons .fff-btn-primary {
    margin-bottom: 12px;
  }

  .seasonal-timeline .divider {
    margin-top: 31px;
  }
}
