.inputWrapper {
  width: 100%;
}

.inputWrapper .inputContainer {
  position: relative;
}

.inputWrapper .inputContainer .inputLabel {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 1.25;
}

.inputWrapper .inputContainer .inputLabel .select {
  display: block;
  height: 48px;
  width: 100%;
  margin-top: 6px;
  background-color: #ffffdc;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 10px;
  font-family: 'Nunito Sans', sans-serif;
  color: black;
  -webkit-appearance: none;
  background: url(https://res.cloudinary.com/fabfitfun/image/upload/q_auto,f_auto/v1638321059/prod/gtb/Form/chevron.png)
    no-repeat right 12px center;
}

.inputWrapper .inputContainer .inputLabel .select:disabled {
  background: #eeeff0
    url(https://res.cloudinary.com/fabfitfun/image/upload/q_auto,f_auto/v1638321059/prod/gtb/Form/chevron-disabled.png)
    no-repeat right 12px center;
}

.inputWrapper .inputContainer .inputLabel .inputError {
  margin: 5px 0 6px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 1.25;
  color: #fb6a66;
  min-height: 15px;
}

.inputPadding {
  padding: 0;
}

.invalid {
  border: 2px solid red !important;
  background-color: #ffe5e5;
}
