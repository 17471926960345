@value shared: '../shared.module.css';
@value fontPlayfair, fontNunitoSans, xl from shared;

.featureListContainer {
  align-items: center;
  background-color: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  font-family: fontNunitoSans;
  padding: 33px 12px 12px;
  text-align: center;
}

.featureListContainer h2 {
  font-family: fontPlayfair;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  margin: 0 0 6px;
}

.featureListContainer p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
}

.featureListContainer ul.featureList {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-top: 24px;
  padding-inline-start: 0;
  width: 351px;
}

.featureListContainer ul.featureList li {
  align-items: center;
  display: flex;
  min-height: 132px;
  flex-direction: row;
  margin-bottom: 15px;
}

.featureListContainer ul.featureList li:last-child {
  margin-bottom: 0;
}

.featureListContainer ul.featureList li .featureImage {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 114px;
}

.featureListContainer ul.featureList li .featureImage img {
  max-height: 111px;
}

.featureListContainer ul.featureList li .featureDescription {
  margin-left: 12px;
  max-width: 220px;
  text-align: left;
}

.featureListContainer ul.featureList li .featureDescription strong {
  display: block;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 6px;
}

.featureListContainer ul.featureList li .featureDescription p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.featureListContainer.icons {
  background-color: #ede7e0;
  padding: 33px 12px 24px;
}

.featureListContainer.icons ul.featureList li p {
  max-width: 285px;
}

.featureListContainer.icons ul.featureList li {
  background-repeat: no-repeat;
  background-size: auto 75px;
  flex-direction: column;
  padding-bottom: 100px;
}

.featureListContainer.icons ul.featureList li:nth-child(odd) {
  background-image: url('./assets/dotted-arrow-left.svg');
  background-position: left 24px bottom;
}
.featureListContainer.icons ul.featureList li:nth-child(even) {
  background-image: url('./assets/dotted-arrow-right.svg');
  background-position: right 24px bottom;
}

.featureListContainer.icons ul.featureList li:last-child {
  background: none;
  padding-bottom: 33px;
}

.featureListContainer.icons ul.featureList li .featureImage {
  margin-bottom: 9px;
}

.featureListContainer.icons ul.featureList li .featureDescription {
  margin: 0;
  text-align: center;
}

@media screen and (min-width: xl) {
  .featureListContainer {
    padding: 37px 12px 24px;
    width: 100%;
  }

  .featureListContainer div.wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .featureListContainer h2 {
    max-width: unset;
  }

  .featureListContainer ul.featureList {
    align-items: start;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    margin-top: 17px;
    width: 100%;
    max-width: 1140px;
  }

  .featureListContainer ul.featureList li {
    flex-direction: column;
    flex: 1;
    min-height: 106px;
    margin: 0;
    padding: 0 1%;
  }

  .featureListContainer ul.featureList li .featureImage {
    min-height: 150px;
  }

  .featureListContainer ul.featureList li .featureImage img {
    max-height: 135px;
    max-width: 144px;
  }

  .featureListContainer ul.featureList li p {
    max-width: 252px;
  }

  .featureListContainer ul.featureList li .featureDescription {
    text-align: center;
    margin: 0;
    max-width: unset;
  }

  .featureListContainer.icons {
    background-color: #ede7e0;
  }

  .featureListContainer.icons div.wrapper {
    background-image: url('./assets/dotted-arrow-down.svg'), url('./assets/dotted-arrow-up.svg');
    background-position:
      left 33% top 48px,
      right 33% bottom 24px;
    background-repeat: no-repeat, no-repeat;
    background-size:
      72px auto,
      72px auto;
    max-width: 1200px;
    padding-bottom: 24px;
  }

  .featureListContainer.icons ul.featureList li p {
    max-width: 300px;
  }

  .featureListContainer.icons ul.featureList {
    justify-content: space-evenly;
    margin-top: 24px;
  }

  .featureListContainer.icons ul.featureList li {
    flex-direction: column;
    padding-bottom: 0;
  }

  .featureListContainer.icons ul.featureList li:nth-child(odd),
  .featureListContainer.icons ul.featureList li:nth-child(even) {
    background: none;
  }

  .featureListContainer.icons ul.featureList li:last-child {
    margin-bottom: 0;
  }

  .featureListContainer.icons ul.featureList li .featureImage {
    margin-bottom: 9px;
    min-height: 42px;
  }

  .featureListContainer.icons ul.featureList li .featureDescription {
    margin: 0;
    min-width: 192px;
    text-align: center;
  }

  .featureListContainer.icons ul.featureList li .featureDescription strong {
    display: block;
    font-size: 16px;
    line-height: 24px;
  }
}
