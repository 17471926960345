@value shared: "../../../shared/shared.module.css";
@value sm from shared;

.container {
  height: 48px;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  font-size: 16px;
  text-align: center;
  border-bottom: 0.1px solid rgba(179, 179, 179, 0.4);
}

.closeButton {
  position: absolute;
  margin-left: auto;
  right: 18px;
  font-size: 20px;
}

.header {
  font-weight: 700;
}
