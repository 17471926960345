.feContainer {
  font-family: 'Nunito Sans', sans-serif;
  position: relative;
  font-size: 12px;
  line-height: 15px;
  padding-bottom: 12px;
  color: #ff3b30;
}

.feContainer > .feMessage {
  margin-top: 5px;
  position: absolute;
}
