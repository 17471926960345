.rightMenuContainer {
  display: none;

  &.loginPageRightMenuContainer {
    a {
      &:nth-child(2) {
        color: #fb6a66;
      }
    }
  }
}

.globalCart {
  margin-right: 0px;
}

@media (min-width: 992px) {
  .rightMenuContainer {
    min-width: 147px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 64px;
  }
  .globalCart {
    margin-right: 0px;
  }
}
