.container {
  box-sizing: border-box;
  max-width: unset;

  @media screen and (min-width: 992px) {
    width: 100%;
  }
}

.form {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  flex-direction: column;
  overflow: visible;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.hidden {
  height: 0;
  overflow: hidden;
}
