.scrollContain {
  overflow-y: hidden;
  position: fixed;
}

.header {
  background-color: white;
  width: 100%;
  box-shadow:
    inset 0 1px 0 0 #e8e8e8,
    inset 0 -1px 0 0 #e8e8e8;
  box-sizing: border-box;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__content > button {
  margin-left: 18px;
}

.header .fff-logo {
  display: flex;
}

.header .fff-logo img {
  width: 143px;
  height: 14px;
}

.header__nmf .header__content {
  width: 100%;
  justify-content: center;
  padding: 17px 0 13px;
}

.cart-container {
  position: absolute;
  right: 15px;
}

.header__overlay {
  height: 100vh;
  width: 100vw;
  background-color: black;
  opacity: 0.6;
  position: fixed;
  z-index: 90;
}

.header__join {
  color: #fb6a66;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 21px 18px;
}

.header .shopping-cart {
  position: relative;
  box-sizing: border-box;
  margin: 9px 12px;
  width: 42px;
  height: 42px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
}

.header .shopping-cart .badge {
  height: 18px;
  width: 18px;
  top: -4px;
  right: -5px;
  background-color: #fb6a66;
  color: #ffffff;
  font-family: 'Nunito Sans';
  font-size: 9px;
  letter-spacing: 0;
  line-height: 12px;
  text-align: center;
  border-radius: 9px;
  position: absolute;
  padding: 3px;
  box-sizing: border-box;
}

.trademark {
  width: 100px;
  height: 45px;
  margin-left: '35%';
}

@media screen and (min-width: 768px) {
  .header .fff-logo img {
    width: 162px;
    height: 16px;
  }
}

@media screen and (min-width: 992px) {
  .header__wrapper {
    display: flex;
    flex: 0 1 1200px;
    justify-content: space-between;
    max-width: 1200px;
  }

  .header__nmf .header__wrapper {
    justify-content: center;
    padding: 0;
  }

  .header.header__nmf {
    padding: 0;
    min-height: 0;
  }

  .header.header__nmf .header__content {
    padding: 13px 0 12px;
  }

  .header__nmf .header__content.with-cart {
    justify-content: space-between;
  }

  .cart-container {
    position: static;
  }

  .header {
    display: flex;
    justify-content: center;
    background-color: #fff;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);
    min-height: 72px;
    padding-left: 60px;
    padding-right: 60px;
  }

  .header.header__nmf .fff-logo img {
    width: 147px;
  }

  .header .fff-logo img {
    height: 20px;
    padding: unset;
    width: 199px;
  }

  .trademark {
    width: 147px;
    height: 47px;
    margin-left: unset;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
  }
  .header__overlay {
    display: none;
  }
  .user-container {
    display: flex;
    /* max-width: 124px; */
    margin-left: 10px;
    box-sizing: border-box;
  }
  .user-container::before {
    content: ' ';
    width: 1px;
    height: 42px;
    display: inline-block;
    background: #d8d8d8;
    /* margin: 0 16px 0 10px; */
  }
  .left-container {
    display: flex;
    align-items: center;
  }
  /* Shop cart */
  .header .shopping-cart {
    box-sizing: border-box;
    border: none;
    margin: 0 0 9px 18px;
    width: 42px;
    height: 42px;
    border-radius: 23px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
  .header .shopping-cart > div:last-child {
    width: 42px;
    height: 42px;
  }
  .header .shopping-cart > div > button {
    width: 42px;
    height: 42px;
  }
}
