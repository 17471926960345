.fff-shared-linkFooter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  .fff-shared-linkFooter-item {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;

    &:first-child {
      border-top: 1px solid #e5e5e5;
    }
  }
}

.fff-shared-Footer-linkFooter .fff-shared-footer-links {
  font-weight: 400;
  text-decoration: none;
  transition: text-decoration 0.2s;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 1.76;
  color: #4a4a4a;
  padding: 0;
  display: block;
  cursor: pointer;
}
.fff-shared-Footer-linkFooter .fff-shared-footer-links:hover {
  text-decoration: underline;
}
.fff-shared-Footer-linkFooter .fff-shared-footer-links:active {
  color: #fb6a66;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .fff-shared-linkFooter-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(250px, 1fr));
    column-gap: 60px;
    row-gap: 40px;
    width: 100%;
    border: 0;
    align-items: flex-start;

    .fff-shared-linkFooter-item {
      width: auto;
      box-sizing: border-box;
      text-align: left;
      gap: 32px;
      border: none;

      &:first-child {
        border: none;
      }

      .fff-shared-linkFooter-column {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;

        .fff-shared-linkFooter-column-header {
          font-weight: bold;
          margin-bottom: 8px;
        }

        .fff-shared-linkFooter-column-item {
          color: #4a4a4a;
          text-decoration: none;
          box-sizing: border-box;

          &:hover {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .fff-shared-linkFooter-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
