@value shared: "../../shared/shared.module.css";
@value sm from shared;

.container {
  position: relative;
  max-width: 44px;
  max-height: 44px;
}

.container p {
  padding: 0;
  margin: 0;
}

@media (min-width: sm) {
  .container {
    float: right;
  }
}
