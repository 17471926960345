.coTitle {
  font-family: 'Playfair Display', sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0;
  text-align: center;
}

.coBody {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 0 0 20px;
}

.coForm {
  display: flex;
  flex-direction: column;
  padding: 18px 0 18px;
  box-sizing: border-box;
  width: 100%;
}

.coSummary {
  flex: 1 0 auto;
  width: initial;
  max-width: 450px;
  padding: 18px 0 18px;
  margin: 0 0 0 30px;
  box-sizing: border-box;
}

.paymentForm {
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow: visible;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.paymentFormContainerHidden {
  height: 0;
  overflow: hidden;
}

.pfContainer {
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 9px;
  padding: 18px;
}

.pfContainer .pfContainerHeaderWrapper {
  display: flex;
  border-bottom: 2px solid #e8e8e8;
  margin: 0 0 18px 0;
}

.pfContainer .pfContainerHeaderWrapper .pfContainerTitle {
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0 10px 10px 0;
}

.pfContainerHeaderWrapper .pfContainerPayments {
  display: flex;
}

.pfContainer .pfContainerHeaderWrapper .pfContainerPayments .paymentIcon {
  max-width: 100px;
  margin: 0 5px;
}

.autologinText {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.autologinText > a {
  color: #3858cb;
}

@media screen and (max-width: 992px) {
  .coBody {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }

  .coForm {
    padding: 18px 0 18px 0;
    max-width: unset;
  }

  .coSummary {
    flex: 1 1 450px;
    padding: 18px 0 18px 0;
    max-width: unset;
    margin: 0;
  }

  .pfContainer {
    box-sizing: border-box;
    max-width: unset;
  }
}
