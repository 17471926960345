@keyframes progress {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(100%, 0, 0);
  }
}

.tabContainer {
  display: flex;
  justify-content: flex-start;
  padding: 5px 0px;
  border-bottom: solid 1px #e8e8e8;
}

.tab {
  width: 170px;
  height: 13px;
  background-color: #e8e8e8;
  position: relative;
  overflow: hidden;
  margin: 10px;
  border-radius: 3px;
}

.tab::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  animation: progress 1s ease-in-out infinite;
  background: linear-gradient(90deg, #e8e8e8, #e3e3e3);
}

.row {
  display: flex;
  padding: 10px 10px 0px 10px;
  justify-content: space-around;
  margin-bottom: 25px;
}

.titlesContainer {
  display: block;
  margin: 0px 5px;
  display: block;
  margin: 0px 5px;
}

.title {
  width: 150px;
  height: 13px;
  background-color: #e8e8e8;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
  margin-left: 10px;
  border-radius: 3px;
}

.title::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  animation: progress 1s ease-in-out infinite;
  background: linear-gradient(90deg, #e8e8e8, #e3e3e3);
}

.button {
  width: 90px;
  height: 20px;
  background-color: #e8e8e8;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  animation: progress 1s ease-in-out infinite;
  background: linear-gradient(90deg, #e8e8e8, #e3e3e3);
}

.price {
  width: 40px;
  height: 13px;
  background-color: #e8e8e8;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
  margin-left: 10px;
  border-radius: 3px;
}

.price::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  animation: progress 1s ease-in-out infinite;
  background: linear-gradient(90deg, #e8e8e8, #e3e3e3);
}

.image {
  width: 63px;
  height: 63px;
  background-color: #e8e8e8;
  position: relative;
  overflow: hidden;
}

.image::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #e8e8e8, #e3e3e3);
  animation: progress 1s ease-in-out infinite;
}

.totalContiner {
  display: flex;
  margin-top: 48px;
  padding: 10px 20px 0px 10px;
  justify-content: space-between;
}

.total {
  width: 80px;
  height: 13px;
  background-color: #e8e8e8;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
  margin-left: 10px;
  border-radius: 3px;
}

.total::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  animation: progress 1s ease-in-out infinite;
  background: linear-gradient(90deg, #e8e8e8, #e3e3e3);
}

.footer {
  height: 90px;
  background-color: #e8e8e8;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.footer::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  animation: progress 1s ease-in-out infinite;
  background: linear-gradient(90deg, #e8e8e8, #e3e3e3);
}
