.navigation-bar {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 60px);
  left: -100vw;
  transition: left 0.6s;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow-y: scroll;
  box-sizing: border-box;
  z-index: 100;
}

.navigation-bar.is-visible {
  left: 0;
}

.navigation-bar ul {
  padding: 0;
  margin: 0;
}

.navigation-bar ul li {
  list-style: none;
  border-bottom: 1px solid #e8e8e8;
  padding: 18px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
}

.navigation-bar ul li a {
  color: #222222;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
}

.navigation-bar ul li a:hover {
  color: gray;
}

.navigation-bar ul ul {
  background-color: #f9f9f9;
  box-shadow: none;
}

.navigation-bar ul ul li {
  background-color: #f9f9f9;
  padding: 15px 10px 15px 0;
  box-shadow: none;
  border-bottom: none;
}

.navigation-bar ul ul li a {
  text-transform: capitalize;
}

.navigation-bar .live {
  color: #ffffff;
  font-family: 'Nunito Sans';
  font-size: 9px;
  font-weight: 800;
  letter-spacing: 1.5px;
  line-height: 12px;
  text-align: center;
  padding: 3px 6px;
  border-radius: 3px;
  background-color: #fb6a66;
  box-shadow: 0 0 0 3px #ffe1e1;
  margin-left: 9px;
  margin-top: -1px;
}

@media screen and (min-width: 992px) {
  /* logout state */
  .is-logout__nav > ul {
    min-width: 310px !important;
    flex-flow: row-reverse;
    margin: 0 !important;
    align-items: center;
  }
  .is-logout__nav > ul li:nth-child(2) {
    order: 1;
  }
  .is-logout__nav > ul li:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 18px;
    height: 42px;
    min-width: 160px;
    background-color: #fb6a66;
    text-align: center;
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
  }
  .is-logout__nav > ul li:first-child:hover {
    background-color: #f8b9b7;
  }
  .is-logout__nav > ul li:first-child a {
    color: #fff;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 18px;
    display: flex;
    justify-content: center;
  }
  .is-logout__nav > ul li:first-child a:hover {
    color: #fff;
  }
  /* logged state */
  .navigation-bar {
    width: unset;
    position: unset;
    left: unset;
    flex-direction: row;
    justify-content: center;
    max-width: 1200px;
    height: 47px;
    padding-bottom: unset;
    overflow-y: inherit;
    box-shadow: unset;
    box-sizing: border-box;
    z-index: 100;
  }
  .navigation-bar ul {
    display: flex;
    min-width: 566px;
    height: 47px;
    margin: 0;
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-end;
  }
  .navigation-bar ul ul {
    padding: 3px 10px 3px 30px;
    min-width: 250px;
    box-sizing: border-box;
  }
  .navigation-bar ul li {
    padding: 0 18px;
    box-shadow: unset;
    cursor: pointer;
    border-bottom: none;
  }
  .navigation-bar ul li a {
    color: #222222;
    font-family: 'Nunito Sans';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    white-space: pre;
  }
  .navigation-bar ul .sub-menu {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .navigation-bar ul .sub-menu ul {
    visibility: hidden;
    transition: visibility 0.01s ease-out 0.01s;
    position: absolute;
    width: 250px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    padding: 6px 0 !important;
    margin: 0;
    overflow: initial;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    z-index: 100;
    height: unset;
    left: calc(50% - 125px);
    top: 68px;
  }
  .navigation-bar ul .sub-menu:hover ul {
    visibility: visible;
    transition: visibility 0.01s ease-in 0s;
  }
  .navigation-bar ul .sub-menu ul li {
    width: 250px;
    background-color: #fff;
    padding: 0;
    box-sizing: border-box;
  }
  .navigation-bar ul .sub-menu ul li a {
    padding: 12px 18px;
  }
  .navigation-bar .profile-button ul li a {
    width: 250px;
    text-align: left;
    box-sizing: border-box;
    text-transform: none;
  }
  .navigation-bar ul .sub-menu ul li:hover {
    background-color: #eee;
  }
  .navigation-bar ul .sub-menu ul li:hover a {
    color: #222;
  }
  .navigation-bar ul .sub-menu ul::before {
    content: '';
    top: -24px;
    padding-top: 12px;
    left: calc(50% - 12px);
    display: block;
    z-index: 100;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.1));
  }
  .navigation-bar ul li a:hover {
    color: #aaa;
  }
  .navigation-bar ul li a i:hover {
    color: #aaa;
  }
}
