.wrapper {
  svg {
    display: none;

    @media (min-width: 768px) {
      display: inline-flex;
      margin-left: 10px;
      margin: 0 5px;
      fill: #9b9b9b;
    }
  }
}
