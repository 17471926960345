.creditCard {
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 700;
  display: flex;
  max-width: 450px;
  height: 60px;
  width: 100%;
  text-transform: none;
}

.lockIcon {
  margin-right: 6px;
  vertical-align: top;
}

.bbbContainer {
  margin: 10px auto;
}

.bbbContainer img {
  width: 138px;
  height: 56px;
}

.payPal {
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #ffc539;
  height: 60px;
  max-width: 450px;
  width: 100%;
  border: none;
}

.applePay {
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: solid 1px #000;
  color: #fff;
  background: #000;
  height: 60px;
  max-width: 450px;
  width: 100%;
  border: none;
}

.applePay svg {
  margin-left: 6px;
  fill: #fff;
}

.venmo {
  font-family: 'Nunito Sans';
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #008cff;
  color: #fff;
  height: 60px;
  max-width: 450px;
  width: 100%;
  border: none;
}

.venmo > img {
  max-width: 75px;
}

.payPal:hover,
.applePay:hover,
.venmo:hover {
  opacity: 0.7;
  cursor: pointer;
}

.payPal > img,
.venmo > img {
  margin-left: 6px;
}

.applePay > img {
  margin-left: 12px;
  filter: invert(100%);
}

.terms {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  padding: 0 10px;
}

.terms a {
  color: #3858cb;
}

.secureImg {
  width: 100px;
  height: 42px;
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payPal:disabled,
.applePay:disabled,
.venmo:disabled {
  background-color: #9b9b9b;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.payPal:disabled:hover,
.applePay:disabled:hover,
.venmo:disabled:hover {
  opacity: 1;
  cursor: not-allowed;
}
