.fff-shared-sm-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  .fff-shared-footer-sm-icon {
    width: 42px;
    opacity: 0.8;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .fff-shared-sm-container .fff-shared-footer-sm-icon {
    padding: 16px 0;
  }
}

@media (max-width: 1200px) {
  .fff-shared-sm-container .fff-shared-footer-sm-icon {
    padding: 0;
  }
}
