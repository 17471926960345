/*!
 * Font Awesome Pro 5.5.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  src: url(https://scontent01.fabfitfun.com/shared/font-awesome-collection/webfonts/fa-solid-900.eot);
  src:
    url(https://scontent01.fabfitfun.com/shared/font-awesome-collection/webfonts/fa-solid-900.eot?#iefix)
      format('embedded-opentype'),
    url(https://scontent01.fabfitfun.com/shared/font-awesome-collection/webfonts/fa-solid-900.woff2)
      format('woff2'),
    url(https://scontent01.fabfitfun.com/shared/font-awesome-collection/webfonts/fa-solid-900.woff)
      format('woff'),
    url(https://scontent01.fabfitfun.com/shared/font-awesome-collection/webfonts/fa-solid-900.ttf)
      format('truetype'),
    url(https://scontent01.fabfitfun.com/shared/font-awesome-collection/webfonts/fa-solid-900.svg#fontawesome)
      format('svg');
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
