.orderSummaryContainer {
  font-family: 'Nunito Sans', sans-serif;
  flex-grow: 1;
  width: 100%;
}

.orderSummary {
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 9px;
  padding: 18px 24px;
  margin: 0 0 18px;
}

.orderSummary .orderSummaryTitleWrapper {
  border-bottom: 2px solid #e8e8e8;
  margin: 0 0 15px;
}

.orderSummary .orderSummaryTitleWrapper .orderSummaryTitle {
  font-size: 18px;
  line-height: 1.33;
  font-weight: normal;
  margin: 0 0 10px;
}

.orderSummary .orderSection {
  padding: 10px 0;
}

.orderSummary .orderSection .orderRow {
  display: flex;
  justify-content: space-between;
}

.orderSummary .orderSection .orderRow .orderText {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  margin: 0;
}

.orderSummary .orderSection .orderRow .orderTextRed {
  color: #fb6a66;
}

.orderSummary .orderSection .orderInputGroup {
  display: flex;
  margin-top: 8px;
}

.orderSummary .orderSection .orderInputGroup .orderInput {
  flex-grow: 1;
  border: solid 1px #eee;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 12px 10px;
}

.orderSummary .orderSection .orderInputGroup .orderInputBtn {
  height: 45px;
  width: 40px;
  min-width: 40px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: #fff;
  background-color: #fb6a66;
  border: none #fb6a66;
  transition: all ease 0.5s;
  display: flex;
  padding: 14px;
}

.orderSummary .orderSection .orderPromoMessage {
  margin: 0;
  font-size: 13px;
  font-style: italic;
}

.orderSummary .hr {
  border-top: 1px solid #eee;
}

.orderLegalTextPadding {
  padding: 0 10px;
}

.orderLegalTextLight {
  line-height: 110%;
  opacity: 0.6;
  font-size: 12px;
}

.orderCTA .ctaBtn {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
  height: 48px;
  width: 100%;
  color: #fff;
  background-color: #fb6a66;
  border: none #fb6a66;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 10px 0 0;
}

.orderCTA .ctaBtn:disabled {
  background-color: #9b9b9b;
}

.orderCTA .ctaBtn:disabled:hover {
  background-color: #9b9b9b;
  cursor: not-allowed;
}

.orderCTA .ctaBtn:hover {
  cursor: pointer;
  background-color: #ff4646;
}

.orderCTA .ctaBtn .lockIcon {
  margin-right: 6px;
}

.orderCTA .secureImg {
  width: 100px;
  margin: 10px auto;
}

.link,
.legalLink {
  color: #fb6a66;
  font-size: inherit;
  text-decoration: underline;
}

.link:visited,
.legalLink:visited {
  color: #fb6a66;
  font-size: inherit;
  text-decoration: none;
}

.link:hover,
.legalLink:hover {
  color: #fa9c98;
  text-decoration: underline;
}

.link:active,
.legalLink:active {
  color: #c75552;
  text-decoration: none;
}

.legalLink {
  color: #3858cb;
}

.legalLink:visited {
  color: #3858cb;
}

.legalLink:hover {
  color: #8ea7ff;
}

.legalLink:active {
  color: #4a72ff;
}

.linkUnderlined,
.linkUnderlined:visited,
.linkUnderlined:active {
  text-decoration: underline;
}

@media (min-width: 992px) {
  .orderSummaryContainer {
    margin: 0;
    max-width: 450px;
    width: 100%;
    flex: 1;
  }

  .orderSummary .orderSection .orderInputGroup .orderInput {
    font-size: 14px;
  }
}
