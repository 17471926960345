@value shared: '../shared.module.css';
@value fontNunitoSans, fontPlayfair, sm, colorPrimaryLight from shared;

.modal {
  padding: 0;
}

.wrapper {
  padding-bottom: 30px;
}

.mobileTitle {
  font-family: fontPlayfair;
  font-size: 30px;
  padding: 20px 20px 0px;
  margin: 0;
}

.header {
  display: none;
}

.header h1 {
  margin: 0;
  padding: 10px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: fontNunitoSans;
  text-align: center;
}

.header button {
  background-color: transparent;
  width: auto;
  height: auto;
  border: none;
  position: absolute;
  margin-top: -24px;
  right: 10px;
  cursor: pointer;
}

.header button svg {
  fill: #000;
}

.image {
  width: 100%;
  height: 209px;
  object-fit: cover;
}

.content {
  padding: 20px;
  padding-top: 0;
  padding-bottom: 12px;
  font-family: fontNunitoSans;
}

.content h2 {
  margin: 0;
  margin-top: 15px;
  padding: 0;
  padding-bottom: 3px;
  font-size: 14px;
  line-height: 18px;
}

.content ul h2 {
  margin-top: 0;
}

.content p,
.content a,
.content ul li section div {
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.content ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding-top: 18px;
}

.content ul li {
  display: flex;
  padding-bottom: 14px;
}

.content ul li section {
  padding-left: 10px;
}

.wrapperIcon {
  padding-top: 3px;
}

.wrapperIcon i {
  font-size: 24px;
  width: 24px;
  color: colorPrimaryLight;
}

.gray {
  color: #666;
}

.wrapperButton {
  display: flex;
  justify-content: center;
}

.copy {
  font-family: fontNunitoSans;
  font-size: 12px;
  text-align: center;
  margin: 0;
  padding-top: 20px;
}

@media (min-width: sm) {
  .mobileTitle {
    display: none;
  }

  .shadow {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrapper {
    max-width: 471px;
    height: auto;
    padding: 30px 50px;
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
    max-height: 100vh;
    background-color: #fff;
    width: 100%;
    border-radius: 5px;
  }

  .header {
    position: static;
    display: block;
  }

  .header h1 {
    font-family: fontPlayfair;
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;
    text-transform: none;
  }

  .header button {
    width: 24px;
    height: 24px;
    top: 10px;
    margin-top: 0;
    border-radius: 50%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header button svg {
    fill: #fff;
    width: 80%;
  }

  .image {
    width: 100%;
    height: auto;
  }

  .content {
    padding: 0;
    padding-top: 20px;
  }

  .content ul {
    padding-top: 25px;
    padding-bottom: 10px;
  }

  .content ul li {
    padding-bottom: 20px;
  }

  .wrapperIcon i {
    font-size: 38px;
    width: 36px;
  }

  .content ul li section {
    padding-left: 25px;
  }

  .content p,
  .content a,
  .content ul li section div {
    font-size: 14px;
  }

  .content > h2,
  .content > p {
    display: inline;
  }

  .content > h2 {
    margin-right: 5px;
  }

  .content ul h2 {
    font-size: 16px;
  }

  .content > h2::after {
    content: ':';
  }

  .wrapperButton button {
    width: 100%;
    cursor: pointer;
  }

  .copy {
    padding-bottom: 0;
  }
}
