.sub-menu ul {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.6s;
  padding: 0 10px 0 30px !important;
}

.sub-menu.is-open ul {
  border-bottom: 1px solid #e8e8e8;
}

.sub-menu ul li:first-child {
  padding-top: 18px;
}

.sub-menu ul li:last-child {
  padding-bottom: 18px;
}

.sub-menu button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 18px;
  margin: -18px -15px -18px 0;
}

.sub-menu button:focus {
  outline: none;
}

.sub-menu button i {
  height: 16px;
  width: 10px;
  color: #222222;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 400;
}

@media screen and (min-width: 992px) {
  .sub-menu ul {
    max-height: unset;
    padding: unset;
  }

  .sub-menu.is-open ul {
    display: block;
    padding: 3px 10px 3px 30px;
  }

  .sub-menu .sub-menu__label {
    white-space: pre;
  }
  .sub-menu .sub-menu__label a {
    margin-right: 6px;
  }
  .sub-menu .sub-menu__label i {
    font-size: 14px;
    font-weight: 400;
  }
  .sub-menu:hover .sub-menu__label a,
  .sub-menu:hover .sub-menu__label i {
    color: #aaa;
  }
}
