.button {
  font-family: 'Nunito Sans', sans-serif;
  height: 48px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  font-size: 16px;
  white-space: nowrap;
  padding: 0 16px;
}

.button:hover {
  cursor: pointer;
}

.primary {
  color: white;
  background-color: #fb6a66;
}

.primary:hover {
  background-color: #f8b9b7;
}

.outline {
  color: #fb6a66;
  background-color: white;
  border: solid 1px #fb6a66;
}

.outline:hover {
  border: solid 1px #f8b9b7;
  color: #f8b9b7;
}

.button:disabled {
  background-color: #d0d0d0;
  color: #ececec;
}

.button:disabled:hover {
  cursor: default;
}

.disabledSecondary {
  color: #9b9b9b !important;
  background-color: #ececec !important;
}
