button.container {
  border: solid 1px #d0d0d0;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  position: relative;
  background-color: white;
  outline: none;
}

button.container:hover {
  border: solid 1px #000000;
}

button.container i {
  font-size: 18px;
  color: #4a4a4a;
}

button.container:hover i {
  color: #000000;
}

button.container span {
  position: absolute;
  background: #fb6a66;
  top: -3px;
  right: -6px;
  min-width: 18px;
  border-radius: 50%;
  line-height: 18px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  z-index: 2;
  font-weight: 400;
}

button.container .maxCount {
  font-size: 8px;
  min-width: 19px;
  line-height: 19px;
}
