.mainMenuContainer {
  display: none;
}

@media (min-width: 992px) {
  .mainMenuContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;

    > *:not(:last-child)::after {
      content: '|';
      margin: 0 48px;
      font-family: 'Nunito Sans';
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
    }
  }
}
