.modal {
  padding: 0;
}

.container {
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  height: fit-content;
  animation-name: popUpwards;
  animation-duration: 300ms;
}

.drawerHandle {
  width: 100%;
  height: 80px;
  position: absolute;
}

.drawerHandle::before {
  content: '';
  position: absolute;
  background-color: #9b9b9b;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 6px;
  border-radius: 30px;
}

.content {
  max-height: 90vh;
  overflow: auto;
}

.swipedDown {
  animation-name: swipeDown;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

@keyframes swipeDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes popUpwards {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
