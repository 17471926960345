.checkboxContainer {
  margin: 0;
}

.checkboxContainer .checkboxLabel {
  color: #4a4a4a;
  cursor: pointer;
  display: flex;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  margin: auto;
  padding: 0;
}

.checkboxContainer .checkboxLabel .checkBoxCheckmark {
  align-items: center;
  border-radius: 4px;
  border: 2px solid #d0d0d0;
  display: flex;
  height: 16px;
  justify-content: center;
  margin-right: 12px;
  margin-top: 0;
  width: 16px;
  flex-shrink: 0;
  background-color: #fff;
}

.checkboxContainer .checkboxLabel .checkboxText {
  max-width: 293px;
}

.checkboxContainer input {
  display: none;
}

.checkboxContainer input:checked ~ .checkBoxCheckmark {
  background-color: #fb6a66;
  border-color: transparent;
}

.checkboxContainer input:checked ~ .checkBoxCheckmark:after {
  display: inline-block;
  content: '';
  width: 4px;
  height: 8px;
  margin-bottom: 2px;
  border: inset #fff;
  align-self: center;
  justify-content: center;
  justify-self: center;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (min-width: 576px) {
  .checkboxContainer .checkboxLabel .checkBoxCheckmark {
    height: 24px;
    width: 24px;
    min-width: 24px;
  }
  .checkboxContainer input:checked ~ .checkBoxCheckmark:after {
    width: 6px;
    height: 10px;
  }
}
