.db-container {
  background-color: #fdf1eb;
  padding: 12px;
  box-sizing: content-box;
  margin: 0;
  min-height: 15px;
  max-height: 126px;
  overflow: hidden;
  position: relative;
}
.db-container.publicShopBanner {
  height: 64px;
  padding: 0;
  display: flex;
  justify-content: center;
}

.db-container-message {
  justify-content: center;
}
.publicShopBanner .db-container-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.db-title {
  display: flex;
  white-space: nowrap;
  justify-content: center;
  margin: auto;
}
.publicShopBanner .db-title {
  margin: 0;
}

.db-title-exclamation {
  width: 16px;
  color: #000000;
  font-family: 'Font Awesome 5 Pro';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: end;
  padding-right: 3px;
  margin: 0;
}
.db-title-text {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: 15px;
  font-weight: 700;
  margin: 0;
}
.db-message {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0;
  line-height: 15px;
  font-weight: 400;
  margin: 1px 0 0 0;
}

.publicShopBanner .db-title-text,
.publicShopBanner .db-message {
  display: inline-flex;
  font-size: 14px;
  line-height: 21px;
}
.publicShopBanner .db-message {
  font-weight: normal;
}

.db-container-close {
  position: absolute;
  background-color: #fdf1eb;
  top: 9px;
  right: 12px;
  cursor: pointer;
  border: none;
  padding: 0;
}

.db-close {
  display: flex;
  align-content: flex-end;
  color: #000000;
  font-family: 'Font Awesome 5 Pro';
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 14px;
  text-align: start;
  flex: 1 1 0;
  margin: 0;
}

.db-close .fa-times {
  font-weight: 300;
}

.db-container-close:hover .db-close {
  color: #fb6a66;
}

@media (min-width: 767px) {
  .db-container {
    display: flex;
    justify-content: space-between;
    padding: 12px;
  }

  .db-container.publicShopBanner {
    height: 56px;
  }

  .db-container-close {
    position: unset;
    display: flex;
    align-items: center;
    margin-left: 36px;
  }

  .db-container-message {
    display: flex;
    flex: 1;
  }

  .db-close {
    font-size: 18px;
  }

  .db-title {
    margin: 1px 3px 0 0;
  }

  .publicShopBanner .db-title-text,
  .publicShopBanner .db-message {
    font-size: 16px;
  }
}

@media (min-width: 767px) and (max-width: 1023px) {
  .db-container {
    padding: 14px 18px 14px 18px;
  }
}

@media (max-width: 1108px) {
  .db-container-message {
    flex-direction: column;
  }
}
