.navLink {
  color: var(--black, #000);
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  background-color: transparent;
}
