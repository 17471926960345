@use '../../sass/colors';
@use '../../sass/fonts';
@use '../../sass/mediaQueries';

.container {
  position: relative;
  background-color: #ede7e0;
  height: 707px;
}

.image {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left bottom;
}

.textWrapper {
  padding: 0 20px;
  max-width: 700px;
  z-index: 1;
  position: relative;

  .title {
    font-family: fonts.$playfair, sans-serif;
    font-size: 40px;
    color: #fff;
    line-height: 53px;
    text-align: center;
    padding-top: 10px;
    margin: 0;
  }

  .description {
    font-family: fonts.$nunito;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 10px 0px;
    font-weight: fonts.$fontWeightBold;
  }

  .disclaimer {
    margin-top: 9px;
    margin-bottom: 18px;
    text-align: center;
  }

  .buttonsWrapper {
    align-items: center;
    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 15px;
      border-radius: 3px;
      width: 100%;
    }
  }
}

@media (min-width: mediaQueries.$medium) {
  .container {
    height: 654px;
    display: flex;
    align-items: center;
  }

  .image {
    object-position: top right;
  }

  .textWrapper {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    z-index: 1;

    .title {
      text-align: left;
      font-size: 54px;
      line-height: 72px;
      padding-top: 0;
      max-width: 500px;
    }

    .description {
      text-align: left;
      padding-top: 30px;
      max-width: 512px;
    }

    .disclaimer {
      max-width: 400px;
      text-align: left;
    }

    .buttonsWrapper {
      flex-direction: row;

      button {
        margin-right: 20px;
        width: 244px;
      }
    }
  }

  .textWrapperVariationB {
    .title {
      max-width: max-content;
    }
  }
}
